// eslint-disable
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { TopTitle } from '../experience/TopTitle';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { VenueCard } from './VenueCard';
import { BookNowBottomStatic } from './BookNowBottomStatic';
import ExperienceDescription from './ExperienceDescription';
import ImageCardExperience from './ImageCardExperience';
import PassDetails from './PassDetails';
import PackageDetails from './PackageDetails';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import RatingsAndReviews from './RatingsAndReviews';
import RatingsAndReviewDrawer from './RatingsAndReviewDrawer';
import UserRegistration from '../ReusableComponent/UserRegistration';

const ExperiencesDetails = () => {
  const { restaurantId, experienceId } = useParams();

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const [openRnR, setOpenRnR] = useState(false);

  const [loginModal, showLoginModal] = useState(false);

  const [count, setCount] = useState(0);

  const [UserData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem('userDetails')) || []
  );
  // console.log('UserData', UserData);

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      //  console.log("Fetching Experience Details API with experienceId:", experienceId);
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, 'GET');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [
    // eslint-disable-next-line
    restaurantCancel,
    restaurantResponseData,
    // eslint-disable-next-line
    restaurantError,
    // eslint-disable-next-line
    restaurantLoaded,
    // eslint-disable-next-line
    restaurantReset,
    restaurantExecuteAPI,
  ] = useAxios();

  /**
   * On component mount fetch the restaurant details from the server
   */
  useEffect(() => {
    if (restaurantId !== undefined && restaurantId !== null) {
      // console.log("Fetching Restaurant Details API with restaurantId:", restaurantId);
      restaurantExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        'GET',
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  const logincallBack = (data) => {
    setUserData(data);
  };
  // console.log('Experience API Response:', responseData);
  // console.log('Restaurant API Response:', restaurantResponseData);
  return (
    <div>
      <div className="experience-details">
        <div
          style={{
            paddingBottom: '10rem',
          }}
        >
          <TopTitle
            title={responseData?.restaurantName || 'Experience'}
            subTitle={'10.3 Kms'}
            subTitleWithIcon={require('../../assets/images/ExperiencePageAssets/location.png')}
            mainIcon={responseData?.restaurantId?.logo_url_thumbnails}
            backRoute={`/restaurant/${restaurantId}/experience`}
          />

          <ImageCardExperience data={responseData} />
          <ExperienceDescription data={responseData} />
          <Image
            src={require('../../assets/images/ExperiencePageAssets/design_1.png')}
            width={'100%'}
            style={{ marginTop: '-20px' }}
          />
          {responseData?.allowSingleBooking && (
            <PassDetails data={responseData} />
          )}

          {/* {responseData?.isPackagesProvided && responseData?.packages?.length && (
            <PackageDetails data={responseData} />
          )} */}


          {/* Venue card */}
          <VenueCard
            data={responseData}
            restaurantData={restaurantResponseData}
          />
          <Image
            src={require('../../assets/images/ExperiencePageAssets/design_2.png')}
            width={'100%'}
          />
          <hr style={{ backgroundColor: '#757575' }} />
          {/* What do you get? */}
          <div className="p-4">
            <div className="font-bold text-lg mb-2">What do you get?</div>
            <div className="f14-400">{responseData?.userBenefits}</div>
          </div>
          <hr style={{ backgroundColor: '#757575' }} />

          <div className="py-4">
            <div className="f18-500 m-2 font-bold">About the Restaurant</div>
            <RestaurantCard restData={restaurantResponseData} />
          </div>
          <hr style={{ backgroundColor: '#757575' }} />

          <div className="pt-4 px-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="f18-500 m-2 font-bold">Ratings & Reviews</div>
              <div
                className="f14-500 text-primary"
                onClick={() => {
                  if (!sessionStorage.getItem('accessToken')) {
                    showLoginModal(true);
                  } else {
                    setOpenRnR(true);
                  }
                }}
              >
                Write A Review
              </div>
            </div>

            <RatingsAndReviews
              experienceId={experienceId}
              data={responseData}
              count={count}
            />
          </div>
          <hr style={{ backgroundColor: '#757575' }} />
          {/* Terms & Conditions */}
          <div className="p-4">
            <div className="f18-500 mb-2 font-bold">Terms & Conditions</div>
            <div className="f14-400">{responseData?.tc}</div>
          </div>
          <hr style={{ backgroundColor: '#757575' }} />
          {/* Terms & Conditions */}
          <div className="p-4">
            <div className="f18-500 mb-2 font-bold">Refund & Cancellation</div>
            <div className="f14-400">{responseData?.refundDesc}</div>
          </div>
        </div>
        <BookNowBottomStatic expData={responseData} />
        {openRnR && <RatingsAndReviewDrawer
          open={openRnR}
          setOpen={setOpenRnR}
          expData={responseData}
          UserData={UserData}
          setCount={setCount}
          count={count}
        />}
        {loginModal ? (
          <UserRegistration
            open={loginModal}
            setshowOtpModal={(val) => showLoginModal(val)}
            logincallback={(val) => logincallBack(val)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ExperiencesDetails;
