import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import MainSideBar from "../../components/MainsideBar";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useAuth from "../../common/hooks/useAuth";
import { useState } from "react";
import Loader from "../../components/loader";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import useApi from "../../common/api/hooks/useApi";
import MainHeader from "../../components/headers/Mainheader";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import { Image } from "react-bootstrap";
import WebViewComponent from "./WebVewComponent";

const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));

const CommunityMain = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const { auth, persist } = useAuth();
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);
  const { handleRequest, loading } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [communityRes, setCommunityRes] = useState();
  const [userDetailsInfo, setUserDetailsInfo] = useState({});

  const handleLogout = () => {
    sessionStorage.removeItem("userInformation");
    sessionStorage.removeItem("userOtpDetails");
    sessionStorage.removeItem("userOtpPayload");
    navigate("/");
  };

  useEffect(() => {
    (async () => {
      if (location.lat) {
        const userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
          const userDetailsInfo = JSON.parse(userDetails);
          setUserDetailsInfo(userDetailsInfo);
          // validate the token first
          await handleRequest("get", `${URL.VALIDATE_TOKEN}`, null, {
            headers: { Authorization: userDetailsInfo?.accessToken },
            successMessage: "Successfully validated the user authentication",
            errorMessage: "User authentication failed",
            onSuccess: async (response) => {
              // fetch all communities from this location
              await handleRequest(
                "get",
                `${URL.GET_USER_COMMUNITITES}?lat=${location.lat}&long=${location.lng}`,
                null,
                {
                  successMessage: "Successfully fetched communites",
                  errorMessage: "Unable to fetch communites",
                  onSuccess: (response) => {
                    setIsLoading(false);
                    setCommunityRes(response);
                  },
                  onError: (error) => {
                    setIsLoading(false);
                    setCommunityRes({
                      status: false,
                      message: "Unable to fetch communites",
                    });
                  },
                }
              );
            },
            onError: (error) => {
              setIsLoading(false);
              handleLogout();
            },
          });
        }
      }
    })();
  }, [location]);

  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      {isLoading ? (
        <Loader />
      ) : communityRes?.status == false ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mb-3">
            <Image
              src={require(`../../assets/images/community/${"inActiveCommunity.png"}`)}
              alt="Image"
              width="100px"
            />
          </div>
          <Typography variant="h4" gutterBottom>
            Ooops!
          </Typography>
          <Typography variant="p" gutterBottom>
            {communityRes?.message}
          </Typography>
        </div>
      ) : (
        <WebViewComponent accessToken={userDetailsInfo?.accessToken} />
      )}
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
    />
  );
};

export default CommunityMain;
