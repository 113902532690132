import React from 'react';
import purpleBanner from '../../assets/images/IconsandImages/purplebanner.png';
import pinkBanner from '../../assets/images/IconsandImages/pinkbanner.png';
import schedule from '../../assets/images/IconsandImages/schedule.png';
import redclock from '../../assets/images/IconsandImages/redclock.png';
import callicon from '../../assets/images/IconsandImages/callicon.png';
import shareicon from '../../assets/images/IconsandImages/shareicon.png';
import QRcode1 from '../../assets/images/IconsandImages/QRcode1.png';
import imagebanner from '../../assets/images/IconsandImages/imagebanner.png';
import moment from 'moment';

const ExpRestaurantDetails = ({ restaurantData, trackData }) => {
  return (
    <div className="container-fluid my-4">
      {/* Header Section */}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="mb-1 text-muted">
            Experience / Restaurant Details / Tracking
          </p>
          <h2 className="mb-1">
            {restaurantData?.restaurant_name || "SKYE - Rooftop by Eternia"}
          </h2>
          <p className="mb-0 text-danger">
            Experience ID {restaurantData?.experience_id || "159961"}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-3">
            <span className="text-success me-1">★</span>
            <span>{restaurantData?.rating || "4.2"}</span>
          </div>
          <button className="btn bg-none me-2">
            <img
              src={callicon}
              alt="Call"
              className="img-fluid"
              style={{ width: "40px" }}
            />
          </button>
          <button className="btn bg-none">
            <img
              src={shareicon}
              alt="Share"
              className="img-fluid"
              style={{ width: "40px" }}
            />
          </button>
        </div>
      </div>

      {/* Purple Banner */}
      <div
        className="rounded my-0 text-white p-4"
        style={{
          backgroundImage: `url(${purpleBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginBottom:"0px",
          height:"90px"
        }}
      >
        <div className="row" style={{ marginTop: "-20px" }}>
          <div className="col-md-6 d-flex align-items-center justify-content-start">
            <div className="d-flex align-items-center bg-none p-3 rounded">
              <div className="text-center me-4">
                <img
                  src={schedule}
                  alt="Calendar"
                  className="img-fluid"
                  style={{ width: "25px" }}
                />
                <p className="mb-0 text-grey">
                  {moment(trackData?.reservationTime).format("DD MMM YYYY")}
                </p>
              </div>
              <div className="border-end"></div>
              <div className="text-center ms-4">
                <img
                  src={redclock}
                  alt="Clock"
                  className="img-fluid"
                  style={{ width: "25px" }}
                />
                <p className="mb-0 text-grey">
                  {moment(trackData?.reservationTime).format("hh:mm A")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button className="btn bg-none">
              <img
                src={QRcode1}
                alt="QR Code"
                className="img-fluid"
                style={{ width: "50px" }}
              />
            </button>
          </div>
        </div>
      </div>

      {/* Pink Banner */}
      <div
        className="rounded my-0 text-center p-4"
        style={{
          backgroundImage: `url(${pinkBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginBottom:"30px",
          height:"135px"
        }}
      >
        <div className="text-start">
          <h6 className="text-muted">Order Reference</h6>
          <h4 className="fw-bold">
            CORPORATE EXPERIENCE SINGLE TICKET CHECK
          </h4>
        </div>
      </div>

      {/* Image Section */}
      <div className="text-center my-4">
        <img
          src={imagebanner}
          alt="Event"
          className="img-fluid rounded"
          style={{ maxWidth: '100%', marginTop: "40px" }}
        />
      </div>
    </div>
  );
};

export default ExpRestaurantDetails;
