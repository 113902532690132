import React, { useState, useEffect } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RestaurantCard = ({ restaurants, from, favorites, onToggleFavorite }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const navigate = useNavigate();

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);
  const uniqueRestaurants = Array.from(
    new Map(restaurants.map((item) => [item._id, item])).values()
  );
  // Check if a restaurant is in the favorites
  const isFavorite = (restaurantId) => favorites?.includes(restaurantId);
  return (
    <Row className="d-flex flex-row justify-content-start flex-wrap">
      {uniqueRestaurants?.map((restaurant, index) => (
        <Col
          key={restaurant?._id || index}
          // md={restaurants?.length < 3 ? 12 : 4}
          //md={restaurants?.length >= 1 && restaurants?.length < 3 ? 12 : 4}
          md={
            restaurants?.length === 2 || restaurants?.length === 3
              ? 6
              : restaurants?.length === 1
              ? 12
              : 4
          }
          // md={4}
          className="d-flex justify-content-center my-2 cursor-pointer"
          onClick={() => {
            if (from == 'ONDC') {
              navigate(`/ondc/${restaurant?._id}/menu`);
            } else {
              navigate(`/restaurant/${restaurant?._id}/food/menu`);
            }
          }}
        >
          <div
            className="restaurant_title_card"
            style={{
              position: 'relative',
              borderRadius: '25px',
              border: '1px solid lightgray',
              backgroundColor: 'white',
              marginTop: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              margin: '0 0px',
              marginBottom: '20px',
            }}
          >
            {/* <div className="justify-content-between align-items-center d-flex first_layer">
              <span
                className="py-1 px-4 f2-500"
                style={{
                  backgroundColor: "rgba(248, 107, 0, 0.9)",
                  color: "white",
                  borderRadius: "15px",
                  padding: "2px 8px",
                  position: "absolute",
                  left: "15px",
                  fontSize: "12px",
                  marginTop: "0px",
                }}
              >
                PREMIUM
              </span>
            </div> */}

            <Row className=" pb-3 m-0 align-items-center">
              <Col xs={3} className="position-relative">
                <Image
                  src={restaurant?.logo_url}
                  className="restaurant_card_image"
                  style={{
                    borderRadius: '50%',
                    width: '70px',
                    height: '70px',
                    marginTop: '18px',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    width: '25px',
                    height: '25px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '3px',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                    transform: 'translate(35%, 15%)',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click event
                    onToggleFavorite(restaurant._id);
                  }}
                >
                  <Image
                    src={
                      isFavorite(restaurant._id)
                        ? require('../../assets/images/IconsandImages/heartcoloured.png')
                        : require('../../assets/images/IconsandImages/heart.png')
                    }
                    alt="heartIcon"
                    style={{
                      width: '12px',
                      height: '12px',
                    }}
                  />
                </div>
              </Col>
              <Col xs={8}>
                <div className="pl-3 pb-3">
                  <div
                    className="f20-700"
                    style={{
                      whiteSpace: 'nowrap',
                      marginTop: '15px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '250px',
                    }}
                  >
                    {restaurant?.restaurant_name}
                  </div>
                  <div
                    className="restaurant-description"
                    style={{
                      fontSize: '13px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '230px',
                    }}
                  >
                    {restaurant?.restaurant_description || ''}
                  </div>
                </div>
                {/* <Row className="pb-1 pl-2 ">
                  <Col
                    xs={9}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Image
                      src={require("../../assets/images/IconsandImages/vegindicator.png")}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "5px",
                      }}
                    />
                    <span style={{ fontSize: "13px", marginRight: "10px" }}>
                      Veg
                    </span>
                    <Image
                      src={require("../../assets/images/IconsandImages/nonveg_indicator.png")}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "5px",
                      }}
                    />
                    <span style={{ fontSize: "13px" }}> Non-Veg</span>
                  </Col>
                </Row> */}
                <Row className="pb-1 pl-2 ">
                  <Col
                    xs={9}
                    className="d-flex align-items-center"
                    style={{
                      justifyContent: restaurant?.vegOnly
                        ? 'flex-start'
                        : 'center', // Align left for only Veg, center for both
                    }}
                  >
                    {restaurant?.vegOnly ? (
                      <>
                        <Image
                          src={require('../../assets/images/IconsandImages/vegindicator.png')}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ fontSize: '13px', marginRight: '10px' }}>
                          Veg
                        </span>
                      </>
                    ) : (
                      <>
                        <Image
                          src={require('../../assets/images/IconsandImages/vegindicator.png')}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ fontSize: '13px', marginRight: '10px' }}>
                          Veg
                        </span>
                        <Image
                          src={require('../../assets/images/IconsandImages/nonveg_indicator.png')}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ fontSize: '13px' }}> Non-Veg</span>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="pb-2 justify-content-between f14-500">
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <Image
                  src={require('../../assets/images/IconsandImages/starnew.png')}
                  alt="star"
                  style={{ width: '18px', height: '18px', marginRight: '5px' }}
                />
                {restaurant?.rating || '0'}
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <Image
                  src={require('../../assets/images/IconsandImages/direction.png')}
                  alt="distanceIcon"
                  style={{ width: '18px', height: '18px', marginRight: '5px' }}
                />
                {restaurant?.userDistance
                  ? `${(restaurant?.userDistance / 1000).toFixed(1)} Kms`
                  : '0.0 Kms'}
              </Col>
              <Col xs={4} className="d-flex align-items-center ">
                <Image
                  src={require('../../assets/images/IconsandImages/clock.png')}
                  alt="clockIcon"
                  style={{ width: '18px', height: '18px', marginRight: '5px' }}
                />
                {restaurant?.isOpen ? 'Open' : 'Closed'}
              </Col>
            </Row>

            <div
              className="verification"
              style={{
                position: 'absolute',
                top: '10px',
                right: '15px',
              }}
            >
              <Image
                src={require('../../assets/images/IconsandImages/surface1.png')}
                alt="verification"
                className="verifiction"
                style={{ width: '15px' }}
              />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default RestaurantCard;
