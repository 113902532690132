import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';
import MainSideBar from '../MainsideBar';
import ScreenTemplate from '../ScreenTemplate';
import { useDispatch } from 'react-redux';
import { logoutUserAsync } from '../../store/slices/userSlice';

const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      sessionStorage.clear();
      await dispatch(logoutUserAsync());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Welcome to Amealio!
      </Typography>
      <PrimaryButton variant="contained" onClick={handleLogout}>
        Logout
      </PrimaryButton>
    </Box>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={content}
    />
  );
};

export default WelcomePage;
