import React, { useRef, useState, useEffect } from 'react';

const ImageScroller = () => {
  const images = [
    require('../../assets/images/IconsandImages/splash_1.png'),
    require('../../assets/images/IconsandImages/splash_2.png'),
    require('../../assets/images/IconsandImages/splash_3.png'),
    require('../../assets/images/IconsandImages/splash_4.png'),
    require('../../assets/images/IconsandImages/splash_5.png'),
  ];

  const scrollerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleScroll = () => {
    const scrollLeft = scrollerRef.current.scrollLeft;
    const width = scrollerRef.current.offsetWidth;
    const index = Math.round(scrollLeft / width);
    setActiveIndex(index);
  };

  useEffect(() => {
    const scroller = scrollerRef.current;
    scroller.addEventListener('scroll', handleScroll);

    return () => {
      scroller.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '600px' }}>
      {/* Image Scroller */}
      <div
        ref={scrollerRef}
        style={{
          display: 'flex',
          overflowX: 'scroll',
          scrollSnapType: 'x mandatory',
          scrollbarWidth: 'none', 
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              minWidth: '100%',
              scrollSnapAlign: 'start',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              alt={`Splash ${index + 1}`}
              style={{ width: '560px', height: '600px' }}
            />
          </div>
        ))}
      </div>

     
    </div>
  );
};

export default ImageScroller;
