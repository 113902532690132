import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expTrackDetails: {}, // Rehydrate state
  expRequest:{},
  expTransaction:{}
};

const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    setExperienceDetails: (state, action) => {
      state.expTrackDetails = action.payload;
    },

    setExpRequest: (state, action) => {
      state.expRequest = action.payload;
    },
    setExpTransaction: (state, action) => {
      state.expTransaction = action.payload;
    },
  },
});

export const { setExperienceDetails, setExpRequest, setExpTransaction } = experienceSlice.actions;
export default experienceSlice.reducer;
