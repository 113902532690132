import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useState } from "react";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import axios from "axios";

const ICONS = {
  RESTAURANT_OPEN: {
    experience: require("../../assets/images/landingPageAssets/experience.png"),
    walk_in: require("../../assets/images/landingPageAssets/walkin.png"),
    wait_list: require("../../assets/images/landingPageAssets/waitList.png"),
    reservation: require("../../assets/images/landingPageAssets/reservation.png"),
    dine_in: require("../../assets/images/landingPageAssets/dine_in_enabled.png"),
    take_away: require("../../assets/images/landingPageAssets/Takeaway_Enabled.png"),
    curb_side: require("../../assets/images/landingPageAssets/Curbside_Enabled.png"),
    skip_line: require("../../assets/images/landingPageAssets/skip_line.png"),
  },
  RESTAURANT_CLOSE: {
    experience: require("../../assets/images/landingPageAssets/expclosed.png"),
    walk_in: require("../../assets/images/landingPageAssets/walkinclosed.png"),
    wait_list: require("../../assets/images/landingPageAssets/waitlistclosed.png"),
    reservation: require("../../assets/images/landingPageAssets/reservationclosed.png"),
    dine_in: require("../../assets/images/landingPageAssets/Dine In _ Closed.png"),
    take_away: require("../../assets/images/landingPageAssets/Takeaway_Closed.png"),
    curb_side: require("../../assets/images/landingPageAssets/Curbside_Closed.png"),
    skip_line: require("../../assets/images/landingPageAssets/Skip the Line_Closed.png"),
  },
};

const OrderTypes = (props) => {
  const RestData = props?.restData;
  const subscriptionData = RestData?.subscription?.casual_dining
    ? RestData?.subscription?.casual_dining_status
    : RestData?.subscription?.fast_food_dining
    ? RestData?.subscription?.fast_food_dining_status
    : RestData?.subscription?.multi_service_business
    ? RestData?.subscription?.multi_service_business_status
    : {};

  const [defaultorderType, setDefaultordertype] = useState(
    props?.defaultorderType
  );

  useEffect(() => {
    if (props?.defaultorderType) {
      setDefaultordertype(props.defaultorderType);
    }
  }, [props?.defaultorderType]);

  const services = [
    {
      name: "dine_in",
      title: "Dine In",
      value: subscriptionData?.ordering?.dine_in_order?.value,
    },
    {
      name: "take_away",
      title: "Take Away",
      value: subscriptionData?.ordering?.take_away?.value,
    },
    {
      name: "curb_side",
      title: "Curb Side",
      value: subscriptionData?.ordering?.cubside_delivery.value,
    },
    {
      name: "skip_line",
      title: "Skip the Line",
      value: RestData?.orderSessionSettings?.skip_line,
    },
  ];

  const [open_status, setOpenStatus] = useState({
    walk_in: false,
    wait_list: false,
    reservation: false,
    experience: false,
    curb_side: false,
    dine_in: false,
    take_away: false,
  });

  useEffect(() => {
    setOpenStatus({
      ...open_status,
      walk_in:
        RestData?.openStatus?.open && RestData?.seatingWaitingTime === "00-00"
          ? true
          : undefined,
      wait_list:
        RestData?.openStatus?.open && RestData?.seatingWaitingTime !== "00-00"
          ? true
          : undefined,
      reservation:
        RestData?.openStatus?.open &&
        RestData?.sessionSettings?.reservations &&
        RestData?.future_activities?.reservation,
      experience: RestData?.openStatus?.open,
      dine_in: RestData?.orderSessionSettings?.dine_in_order,
      take_away: RestData?.orderSessionSettings?.take_away,
      curb_side: RestData?.orderSessionSettings?.curb_side,
      skip_line: RestData?.orderSessionSettings?.skip_line,
    });
  }, []);

  const navigate = useNavigate();

  const handleOrderselect = (data) => {
    if (props.storedItems.length >= 1) {
      props.openPriceAlert();
    }
    if (RestData?.openStatus?.open) {
      setDefaultordertype(data.name);
      props?.setDefaultordertype(data.name);
      sessionStorage.setItem("defaultOrderType", data.name);
    }
  };

  return (
    <div className="services py-3 hidescrollbar cursor-pointer">
      {services.map((data) => {
        return (
          <React.Fragment key={data?.name}>
            {data?.value ? (
              <div className="services-images-inside my-2">
                <div
                  className="text-center"
                  onClick={() => handleOrderselect(data)}
                >
                  <Image
                    alt="image"
                    title={data?.title}
                    roundedCircle
                    width="60px"
                    height="60px"
                    src={
                      ICONS[
                        open_status[data?.name]
                          ? "RESTAURANT_OPEN"
                          : "RESTAURANT_CLOSE"
                      ][data?.name]
                    }
                    // src={ICONS['RESTAURANT_OPEN'][data?.name]}
                    className={
                      defaultorderType === data.name ? "border-active" : ""
                    }
                  />
                  <div className="f14-400 nowrap py-2">{data?.title}</div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default OrderTypes;
