import React, { useState, useEffect } from "react";
import FilterSidebar from "./FilterSideBar";

const CATEGORY_IDS = {
  FOOD_TYPE: "5f562c9094ac7fdf47ea1d1d",
  RESTAURANT_TYPE: "5f562c9094ac7fdf47ea1d0a",
  LIQUORS: "5f562c9094ac7fdf47ea1d1c",
};

const SortByList = [
  { name: "Relevance", enum: 0 },
  { name: "Wait Time", enum: 1 },
  { name: "Ratings", enum: 5 },
  { name: "Has Offers", enum: 3 },
  { name: "Open Now", enum: 4 },
];

const FILTER_TITLES = {
  SORT_BY: "Popular",
  FOOD_TYPE: "Sort By",
  RESTAURANT_TYPE: "Restaurant Type",
  LIQUORS: "Liquor Availability",
};

const HomeFilter = ({ onSortChange, onFilterChange, onApplyFilters }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dynamicSubcategories, setDynamicSubcategories] = useState({
    FOOD_TYPE: [],
    RESTAURANT_TYPE: [],
    LIQUORS: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    SORT_BY: [],
    FOOD_TYPE: [],
    RESTAURANT_TYPE: [],
    LIQUORS: [],
  });

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const promises = Object.keys(CATEGORY_IDS).map(async (key) => {
          const response = await fetch(
            `https://uatmerchantapi.amealio.com/subcategory?category_id=${CATEGORY_IDS[key]}`
          );
          const data = await response.json();
          return { [key]: data };
        });

        const results = await Promise.all(promises);
        setDynamicSubcategories(Object.assign({}, ...results));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, []);

  const handleFilterClick = (filterName) => {
    if (activeFilter === filterName) {
      setActiveFilter(null);
      setIsSidebarOpen(false);
    } else {
      setActiveFilter(filterName);
      setIsSidebarOpen(true);
    }
  };

  const handleCheckboxChange = (filterType, subcategoryId) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters[filterType] = updatedFilters[filterType].includes(
      subcategoryId
    )
      ? updatedFilters[filterType].filter((id) => id !== subcategoryId)
      : [...updatedFilters[filterType], subcategoryId];

    setSelectedFilters(updatedFilters);

    if (onFilterChange) {
      onFilterChange(filterType, updatedFilters[filterType]);
    }

    if (filterType === "SORT_BY" && onSortChange) {
      onSortChange(subcategoryId);
    }
  };

  const handleReset = () => {
    setSelectedFilters({
      SORT_BY: [],
      FOOD_TYPE: [],
      RESTAURANT_TYPE: [],
      LIQUORS: [],
    });

    if (onFilterChange) {
      Object.keys(selectedFilters).forEach((filterType) => {
        onFilterChange(filterType, []);
      });
    }

    handleSidebarClose();
  };

  const handleApply = () => {
    if (onApplyFilters) {
      onApplyFilters(selectedFilters);
    }
    setActiveFilter(null);
    setIsSidebarOpen(false);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
    setActiveFilter(null);
  };

  return (
    <div className="filter-buttons-container scrollable pb-2 w-full">
      {Object.keys(FILTER_TITLES).map((filter) => (
        <button
          key={filter}
          className={`filter-button border nowrap text-base font-Mulish font-semibold ${
            activeFilter === filter ? "active-filter" : "bg-[#FBFBFB]"
          }`}
          // style={{ width: "100%" }}
          onClick={() => handleFilterClick(filter)}
        >
          {FILTER_TITLES[filter]}
        </button>
      ))}

      <FilterSidebar
        isOpen={isSidebarOpen}
        onClose={handleSidebarClose}
        title={FILTER_TITLES[activeFilter]}
      >
        {activeFilter && (
          <div>
            <h4>{FILTER_TITLES[activeFilter]}</h4>
            {activeFilter === "SORT_BY"
              ? SortByList.map((option) => (
                  <div key={option.enum}>
                    <input
                      type="checkbox"
                      id={option.enum}
                      onChange={() =>
                        handleCheckboxChange("SORT_BY", option.enum)
                      }
                      checked={selectedFilters.SORT_BY.includes(option.enum)}
                    />
                    <label htmlFor={option.enum}>{option.name}</label>
                  </div>
                ))
              : dynamicSubcategories[activeFilter].map((subcategory) => (
                  <div key={subcategory.id || subcategory._id}>
                    <input
                      type="checkbox"
                      id={subcategory.id || subcategory._id}
                      onChange={() =>
                        handleCheckboxChange(
                          activeFilter,
                          subcategory.id || subcategory._id
                        )
                      }
                      checked={selectedFilters[activeFilter].includes(
                        subcategory.id || subcategory._id
                      )}
                    />
                    <label htmlFor={subcategory.id || subcategory._id}>
                      {subcategory.name || subcategory.title}
                    </label>
                  </div>
                ))}
          </div>
        )}

        <div className="filter-footer">
          <button className="reset-button" onClick={handleReset}>
            Reset
          </button>
          <button className="apply-button" onClick={handleApply}>
            Apply
          </button>
        </div>
      </FilterSidebar>
    </div>
  );
};

export default HomeFilter;
