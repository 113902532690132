import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

const PageNotFound = (props) => {
  const navigate = useNavigate();
  return (
    <Row className="h-screen justify-center items-center">
      <Col sm={12} md={8} className="text-center">
        <h3 className="text-[#333333] font-Mulish font-extrabold text-[200px]">
          404
        </h3>
        <h5 className="text-[#333333] font-Mulish font-extrabold text-4xl">
          Look like you're lost
        </h5>
        <p className="text-[#333333] font-Mulish font-semibold text-xl">
          The page you are looking for not available!
        </p>
        <button
          className="p-2 text-white hover:text-black text-base font-Mulish font-medium rounded-md bg-primaryRed hover:bg-[#eeb84e]"
          onClick={() => navigate("/")}
        >
          Go Back To Homepage
        </button>
      </Col>
    </Row>
  );
};

export default PageNotFound;
