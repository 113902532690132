export const servicesImages = {
  TakeAway: require('./Food/services/takeaway-service.png'),
  CurbSide: require('./Food/services/curbside-service.png'),
  DineIn: require('./Food/services/dineinservice.png'),
  SkipTheLine: require('./Food/services/skiptheline-service.png'),
  Delivery: require('./Food/services/delivery-service.png'),
  WaitList: require('./Food/services/waitlist-service.png'),
  Reservation: require('./Food/services/reservation-service.png'),
  WalkIn: require('./Food/services/walkin-service.png'),
  TakeAwaySelected: require('./Food/services/takeAway.png'),
  CurbSideSelected: require('./Food/services/curbsideselected.png'),
  DineInSelected: require('./Food/services/dineinselected.png'),
  SkipTheLineSelected: require('./Food/services/skipthelineselected.png'),
  DeliverySelected: require('./Food/services/deliveryselected.png'),
  WaitListSelected: require('./Food/services/waitlistselected.png'),
  ReservationSelected: require('./Food/services/reservation.png'),
  WalkInSelected: require('./Food/services/walkinselected.png'),
};

export const seatingAssets = {
  celebrationCap: require('./IconsandImages/celebration-cap.png'),
  adults: require('./seating/adults.png'),
  kids: require('./seating/kids.png'),
  handicap: require('./seating/handicap.png'),
  highchair: require('./seating/highchair.png'),
  seatingpreferences: require('./seating/seatingpreferences.png'),
};
