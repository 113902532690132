import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import backImages from "../../assets/images/Food/Screenshot_1735811956.png";
import propTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const ColorButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#FFFFFF",
  border: "1px solid #FFFFFF",
  backgroundColor: "#EE3A23",
  "&:hover": {
    color: "#EE3A23",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EE3A23",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "10px",
  color: "#EE3A23",
  border: "1px solid #EE3A23",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#EE3A23",
    border: "1px solid #FFFFFF",
  },
}));

const SwitchMenuModal = (props) => {
  const { isVisible, handleClose, handleOnSubmit, items, selectedId } = props;
  console.log("itemsResponse==>39", items);
  const [selectedMenuId, setSelectedMenuId] = useState(selectedId);
  const [selectedMenuArr, setSelectedMenuArr] = useState(selectedId);

  const onClickAction = (data, index) => () => {
    setSelectedMenuId(index);
    setSelectedMenuArr(data);
  };

  const onClickHandleOnSubmit = () => {
    handleOnSubmit(selectedMenuId, selectedMenuArr);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isVisible}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      className="switch-menu-combo-modal"
    >
      <Modal.Header className={"sm:px-2 md:px-4 sm:py-3 md:py-3 border-0"}>
        <div className="w-full flex items-center justify-between">
          <h4 className="text-2xl font-Mulish font-extrabold text-black">
            Select Menu
          </h4>
          <button className="w-7 h-7" onClick={handleClose}>
            <img
              src={require("../../assets/images/IconsandImages/cantsay_icon.png")}
              className="w-full h-auto"
              alt={"Closed"}
              title={"Closed"}
            />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="h-auto pt-0 border-0 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-Mulish font-semibold text-borderLightColor">
            Menu Name
          </h5>
          <h5 className="text-lg font-Mulish font-semibold text-borderLightColor">
            Menu Description
          </h5>
        </div>
        <div className="h-5/6 rounded-xl p-2 border-2 border-black overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-100 [&::-webkit-scrollbar-track]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400">
          {items.map((itemData, index) => {
            return (
              <button
                className={`w-full h-12 flex items-center justify-between mb-3 border rounded-lg px-2 ${
                  selectedMenuId === index && "shadow-red-500 shadow-sm"
                }`}
                key={index}
                onClick={onClickAction(itemData, index)}
              >
                <div className="flex items-center">
                  {/* <input
                    id="red-radio"
                    type="radio"
                    value={itemData?.menuName}
                    name="colored-radio"
                    className={"w-4 h-4"}
                  /> */}
                  <div
                    className="w-5 h-5 rounded-full flex justify-center items-center"
                    style={{
                      border: `1.2px solid ${
                        selectedMenuId === index ? "#EE3A23" : "#94a3b8"
                      }`,
                    }}
                  >
                    <div
                      className={`w-3 h-3 rounded-full ${
                        selectedMenuId === index ? "bg-red-500" : "bg-white"
                      }`}
                    ></div>
                  </div>
                  <label
                    htmlFor="red-radio"
                    className={`ms-2 text-sm font-medium ${
                      selectedMenuId === index
                        ? "text-red-600"
                        : "text-gray-900"
                    }`}
                  >
                    {itemData?.menuName}
                  </label>
                </div>
                <h5
                  className={`text-sm font-medium ${
                    selectedMenuId === index ? "text-red-600" : "text-gray-900"
                  } mb-0`}
                >
                  {itemData?.menuType !== null &&
                  itemData?.menuType !== undefined
                    ? itemData?.menuType
                    : "N/A"}
                </h5>
              </button>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="sm:w-full md:w-1/2 md:float-end justify-end items-end">
          <Stack spacing={2} direction="row">
            <OutlinedButton variant="outlined" onClick={handleClose}>
              Cancel
            </OutlinedButton>
            <ColorButton variant="contained" onClick={onClickHandleOnSubmit}>
              Submit
            </ColorButton>
          </Stack>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SwitchMenuModal.propTypes = {
  isVisible: propTypes.bool.isRequired,
  items: propTypes.array || propTypes.object || propTypes.string,
  handleClose: propTypes.func.isRequired,
  handleOnSubmit: propTypes.func.isRequired,
  selectedId: propTypes.string,
};

export default SwitchMenuModal;
