import React, { useState } from 'react';
import { Image, Form } from 'react-bootstrap';
import { RWebShare } from 'react-web-share';

const TitleSectionNew = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [callOpen, setCallOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (props?.onclicksearch) {
      props.onclicksearch(searchQuery);
    }
  };

  return (
    <div
      className={`title-section-with-search d-flex align-items-center py-3 ${
        props.className || ''
      }`}
      style={{ backgroundColor: props.bg || '#FFFFFF' }}
    >
      <div className="row w-100 m-0">
        <div className="col-8 d-flex align-items-center">
          {/* Restaurant Logo */}
          {props?.resticon && (
            <span>
              <Image
                src={props.resticon}
                alt="Restaurant Logo"
                width="60px" // Increased the size of the logo
                height="60px"
                style={{
                  maxWidth: '100%',
                  borderRadius: '5px', // Reduced border radius for less roundness
                }}
              />
            </span>
          )}
          <div className="mx-2">
            <div className="f20-700 px-1">{props?.title}</div>

            {(props.rating || props.reviews) && (
            <div className="d-flex align-items-center justify-content-between w-100">
    
              {/* Rating & Reviews Section */}
              <div className="d-flex align-items-center">
                {/* Star Icon */}
                <Image
                 src={require('../../assets/images/IconsandImages/starnew.png')} 
                 alt="Star Icon"
                 width="14px"
                 height="14px"
                 style={{ marginRight: '5px' }}
                />
      
                {/* Rating */}
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                 {props.rating || 'N/A'}
                </span>

                {/* Reviews (Blue Link) */}
                <span
                style={{
                fontSize: '14px',
                color: '#007BFF', // Blue color
                marginLeft: '5px',
                fontWeight: '500',
                }}
                >   
                      ({props.reviews || 0} reviews)
                </span>
              </div>

    {/* Time & Distance Section */}
    {props.time && props.distance && (
      <div className="d-flex ml-1 align-items-center">
        {/* Timer Icon */}
        <Image
          src={require('../../assets/images/IconsandImages/timer.png')} 
          alt="Time Icon"
          width="14px"
          height="14px"
          style={{ marginRight: '5px' }}
        />

        {/* Time & Distance Text */}
        <span style={{ fontSize: '14px', color: '#555', whiteSpace: 'nowrap' }}>
          {props.time} min ({props.distance} KMS)
        </span>
      </div>
    )}
  </div>
)}


            <div
              className="f10-700 px-1 opacity-50 nowrap "
              style={{
                color: props?.subtitlecolor ? props?.subtitlecolor : '',
              }}
            >
              {' '}
              {props.subtitle}
            </div>
          </div>
        </div>

        {/* Search Bar */}
        {props.noIconDisplay ? (
          <></>
        ) : (
          <div className="d-flex justify-content-end align-items-center col-4 px-4 mt-1">
            {props?.icon && (
              <>
                <div className=" px-2">
                  <Image
                    src={props?.icon}
                    alt="icon"
                    width="16px"
                    height="16px"
                    style={{ maxWidth: '100%' }}
                    onClick={() => {
                      if (props?.onclicksearch()) props?.onclicksearch();
                    }}
                  />
                </div>
              </>
            )}
            {props?.callicon && (
              <div className="px-2 mt-1" onClick={() => setCallOpen(true)}>
                <Image
                  src={require('../../assets/images/IconsandImages/call.png')}
                  alt="icon"
                  width="28px"
                  // height="24px"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            )}
            {props?.shareIcon && (
              <div className="px-2">
                <RWebShare
                  data={props?.shareObj || ''}
                  onClick={() => console.log('shared successfully!')}
                >
                  <Image
                    src={props?.shareIcon}
                    alt="icon"
                    width="16px"
                    height="16px"
                    style={{ maxWidth: '100%' }}
                  />
                </RWebShare>
              </div>
            )}

            {props?.rightText && (
              <>
                <div
                  onClick={() => props.resetfun()}
                  className="d-flex justify-content-end col-4 px-4 mt-1 text-primary"
                >
                  {props?.rightText || ''}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleSectionNew;
