import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { useParams } from "react-router-dom";
import TitleSection from "../../ReusableComponent/TitleSection";
import { URL } from "../../../common/api/urls";
import moment from "moment";
import { Image } from "react-bootstrap";
import VideoPlayer from "../../seating/VideoPlayer";
import RecommendedFoodItems from "../../seating/RecommendedFoodItems";
import OrderDetails from "./OrderDetails";
import {
  ORDER_STATUS,
  ORDER_TYPE_NAME,
} from "../../../common/enums/OrderEnums";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SocketContext from "../../../setup/socket/SocketContext";
import CountDown, {
  TrackCountDown,
} from "../../ReusableComponent/countdownTimer";
import UserRegistration from "../../ReusableComponent/UserRegistration";
import TrackScreenQr from "./TrackScreenQr";
import { getStatusMessage } from "../../../common/utility/helperFunc";
import MainSideBar from "../../../components/MainsideBar";
import useLocation from "../../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../../config/keys";
import MainHeader from "../../../components/headers/Mainheader";
import ScreenTemplate from "../../../components/ScreenTemplate";
import useWindowWidth from "../../../common/hooks/useWindowWidth";

function OrderTrack(props) {
  const { ID, order_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [restaurantData, setRestaurantData] = useState({});
  const [promoVideos, setPromoVidos] = useState([]);
  const [recFood, setRecFood] = useState([]);
  const [trackData, setTrackData] = useState([]);
  const [openQr, setQrmodal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [countDownTime, setCountDownTime] = useState(0);

  const windowWidth = useWindowWidth();

  const isDesktop = windowWidth > 1024;

  const handleClickOpenQr = () => {
    setQrmodal(true);
  };
  const handleCloseQr = () => {
    setQrmodal(false);
  };

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const [
    // eslint-disable-next-line
    restCancel,
    restResponseData,
    // eslint-disable-next-line
    restError,
    // eslint-disable-next-line
    restLoaded,
    // eslint-disable-next-line
    restReset,
    restExecuteAPI,
  ] = useAxios();

  const [
    // eslint-disable-next-line
    recommendFoodCancel,
    recommendFoodResponseData,
    // eslint-disable-next-line
    recommendFoodError,
    // eslint-disable-next-line
    recommendFoodLoaded,
    // eslint-disable-next-line
    recommendFoodReset,
    recommendFoodExecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    orderTrackcancel,
    ordertrackresponseData,
    orderTrackerror,
    orderTrackloaded,
    orderTrackreset,
    orderTrackexecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (
      sessionStorage.getItem("restaurantData") &&
      ID == JSON.parse(sessionStorage.getItem("restaurantData"))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem("restaurantData")));
    } else {
      restExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${ID}?lat=true&long=true`,
        "GET",
        null
      );
    }
  }, []);

  const [
    // eslint-disable-next-line
    promotionalvideosCancel,
    promotionalvideosResponseData,
    // eslint-disable-next-line
    promotionalvideosError,
    // eslint-disable-next-line
    promotionalvideosLoaded,
    // eslint-disable-next-line
    promotionalvideosReset,
    promotionalvideosExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (restaurantData.vendorId)
      promotionalvideosExecuteAPI(`${URL.PROMOTIONAL_VIDEOS}`, "GET", null, {
        Authorization: sessionStorage.getItem("accessToken"),
      });
  }, [restaurantData.vendorId]);

  useEffect(() => {
    if (promotionalvideosResponseData) setLoading(false);
    setPromoVidos(promotionalvideosResponseData);
  }, [promotionalvideosResponseData]);

  useEffect(() => {
    if (restaurantData.vendorId)
      recommendFoodExecuteAPI(
        `${URL.RECOMMENDED_FOOD}?vendor_id=${restaurantData.vendorId}`,
        "GET",
        null,
        {
          Authorization: sessionStorage.getItem("accessToken"),
        }
      );
  }, [restaurantData.vendorId]);

  useEffect(() => {
    if (restaurantData.vendorId && recommendFoodResponseData)
      setRecFood(recommendFoodResponseData);
  }, [recommendFoodResponseData]);

  const getOrderData = async () => {
    orderTrackexecuteAPI(`${URL.USER_ORDER}/${order_id}`, "GET", null, {
      Authorization: sessionStorage.getItem("accessToken"),
    });
  };
  const [showOtpModal, setshowOtpModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getOrderData();
      // Calculate countdown time after getOrderData completes
      // const timeData = getTimeData();
      // setCountDownTime(timeData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (ordertrackresponseData) {
      setTrackData(ordertrackresponseData);
    }
  }, [ordertrackresponseData]);

  useEffect(() => {
    if (trackData) {
      const timeData = getTimeData(trackData);
      setCountDownTime(timeData);
    }
  }, [trackData]);

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        sessionStorage.getItem("accessToken")
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log("Socket Re-Authenticated");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    props?.socket?.service("ordering").on("order_trigger", (_data) => {
      setTrackData(_data);
      //}
    });
  }, []);

  const message = getStatusMessage(trackData, trackData?.order_status);

  const status = trackData?.order_status;

  const getTimeData = (trackData) => {
    // try {
    if (trackData?.max_time_date || trackData?.OrderAcceptTime) {
      const updatedTime = moment(
        trackData?.max_time_date || trackData?.OrderAcceptTime
      ).add(parseInt(trackData?.max_time), "minutes");

      let diff = updatedTime.diff(moment());

      return diff;
    } else {
      return 0;
    }
  };

  const content = (
    <Box>
      {showOtpModal ? (
        <UserRegistration
          open={showOtpModal}
          setshowOtpModal={(val) => {
            // if (!val && sessionStorage.getItem("accessToken")) {
            //   getOrderData();
            // }
            setshowOtpModal(val);
          }}
          noNavigationOnSubmit={true}
        />
      ) : null}
      {loading && !promoVideos && !trackData ? (
        <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
          <CircularProgress sx={{ marginTop: "20%" }} />
        </div>
      ) : (
        <>
          <div>
            <TitleSection
              title={restaurantData?.restaurant_name || ""}
              subtitle={`REQUEST ID: ${trackData?.order_id || ""}`}
              resticon={restaurantData?.logo_url_thumbnails}
              className={"box-shadow-down"}
              backRoute={`/restaurant/${ID}`}
              callicon={true}
            />
            <div className="row mx-0 bg-indigo py-2">
              <div className="col-5 d-flex justify-content-between">
                <div className="text-center">
                  <Image
                    src={require("../../../assets/images/IconsandImages/schedule.png")}
                    alt="icon"
                    width="20px"
                    height="20x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f10-700">
                    {moment(trackData?.createdAt).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="text-center ">
                  <Image
                    src={require("../../../assets/images/IconsandImages/clock.png")}
                    alt="icon"
                    width="20px"
                    height="20x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f10-700">
                    {moment(trackData?.OrderAcceptTime).format("hh:mm A")}
                  </div>
                </div>
              </div>
              <div className="col-7 d-flex justify-content-end">
                <div className="f16-700 text-indigo text-center mt-3 mr-2">
                  {trackData?.order_status === 1 ||
                  trackData?.order_status === 2 ||
                  trackData?.order_status === 3 ||
                  trackData?.order_status === 4 ||
                  trackData?.order_status === 5 ||
                  trackData?.order_status === 6 ? (
                    <>
                      <CountDown waitNew={countDownTime ? countDownTime : 0} />
                    </>
                  ) : null}

                  {/* <TrackCountDown untill={getTimeData()} /> */}
                </div>
                <div onClick={handleClickOpenQr}>
                  <Image
                    src={require("../../../assets/images/IconsandImages/viewqr.png")}
                    alt="icon"
                    width="52px"
                    // height="25x"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-p2 py-2">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <div className="f14-700 opacity-50">Order References:</div>
                  <div className="f14-700">
                    {ORDER_TYPE_NAME[trackData?.order_type]}
                  </div>
                </div>
                <div>
                  {trackData?.order_type === 3 || trackData?.order_type == 2 ? (
                    <>
                      <div className="f12-700 ">
                        {" "}
                        Table No:{" "}
                        <span className="f14-700">
                          {" "}
                          {trackData?.dine_in_details?.table_number}
                        </span>
                      </div>
                      <div className="f12-700">
                        Pax: <span>{trackData?.totalDinePax} </span>
                      </div>
                      <PaxChange trackData={trackData} />
                    </>
                  ) : null}
                  {trackData?.order_type === 1 ? (
                    <>
                      <div className="f12-700 ">Pickup POint</div>
                      <div className="f12-700 text-grey">
                        {trackData?.curb_side_details?.pickUpPoint}
                      </div>
                      {trackData?.order_type === 3 ||
                      trackData?.order_type == 2 ? (
                        <PaxChange trackData={trackData} />
                      ) : null}
                    </>
                  ) : null}
                </div>
                {/* <div>
                <Image
                  src={require('../../../assets/images/IconsandImages/viewqr.png')}
                  alt="icon"
                  width="52px"
                  style={{ maxWidth: '100%' }}
                />
              </div> */}
              </div>
            </div>
            <div className="m-3">
              <VideoPlayer videosData={promoVideos} />
            </div>
            {recFood.length > 0 ? (
              <>
                <div className="f18-700 mb-3 px-3">Recommended Food</div>
                <RecommendedFoodItems hide={true} item={recFood} />
              </>
            ) : null}
            <OrderDetails
              data={trackData}
              fontsize={false}
              open={cancelModal}
              setOpen={setCancelModal}
            />
            <div className="mt-3" style={{ marginBottom: "10rem" }}>
              {" "}
              <Image
                src={require("../../../assets/images/IconsandImages/referearn.png")}
                fluid
                width="100%"
                style={{ maxWidth: "100%" }}
              />
              <div className="m-4"></div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: "100",
              }}
            >
              <div className="col-12 col-md-10 col-lg-10 m-0">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="p-2 primary-bg-color d-flex justify-content-between nestedjustify-between"
                    style={{
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <div className="row m-0 w-100">
                      <div className="col-8">
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="f14-700 text-white mx-2 ">
                            {message[status || 0]?.mainText || "pending"}
                          </span>
                        </div>
                      </div>
                      <div className="col-4 nowrap flexCenter">
                        <div
                          className="f12-500 text-white"
                          style={{ textDecoration: "underline" }}
                        >
                          {" "}
                          View Updates
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {trackData?.auditLogs ? (
                      <>
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          {trackData?.auditLogs.map((data) => {
                            return (
                              <TimelineItem key={data._id}>
                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <div className="f12-400">
                                    {data?.initiator}
                                  </div>
                                  <div className="f14-700">{data?.message}</div>
                                  <div className="f10-400">
                                    {" "}
                                    {moment(data?.timestamp).format(
                                      "DD MMM YYYY"
                                    )}{" "}
                                    {"|"}{" "}
                                    {moment(data?.timestamp).format("hh:mm A")}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Timeline>
                      </>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          <TrackScreenQr
            open={openQr}
            handleCloseQr={handleCloseQr}
            data={trackData}
          />
        </>
      )}
    </Box>
  );
  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
}

const OrderTrackScreen = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OrderTrack {...props} socket={socket} />}
  </SocketContext.Consumer>
);

const PaxChange = ({ trackData }) => {
  const [showPaxModal, setPaxModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedPax, setSelectedPax] = useState(1);

  const restData = JSON.parse(sessionStorage.getItem("restaurantData"));
  const subscriptionData = restData?.subscription?.casual_dining
    ? restData?.subscription?.casual_dining_status
    : restData?.subscription?.fast_food_dining
    ? restData?.subscription?.fast_food_dining_status
    : restData?.subscription?.multi_service_business
    ? restData?.subscription?.multi_service_business_status
    : {};

  const maxPaxValue = subscriptionData?.ordering?.dine_in_order?.maximum_person;

  // eslint-disable-next-line
  const [
    ordercancel,
    orderresponseData,
    ordererror,
    orderloaded,
    orderreset,
    orderexecuteAPI,
  ] = useAxios();

  const handleCancel = () => {
    setPaxModal(false);
  };

  const handlePaxChange = (event) => {
    const value = event.target.value;
    setSelectedPax(value);
  };

  const handlePaxButtonRangeClick = (value) => {
    setSelectedPax(value);
  };

  const handleProceed = () => {
    orderexecuteAPI(
      `${URL.USER_ORDER}/${trackData?._id}`,
      "PATCH",
      {
        totalDinePax: selectedPax,
        dine_in_details: {
          table_number: selectedTable,
        },
      },
      {
        Authorization: sessionStorage.getItem("accessToken"),
      }
    );
    setPaxModal(false);
  };

  return (
    <div>
      <div
        className="f12-700"
        style={{ color: "#2196f3", cursor: "pointer" }}
        onClick={() => setPaxModal(true)}
      >
        {" "}
        change
      </div>
      <Dialog open={showPaxModal}>
        <DialogTitle mt={2}>{"Enter Table Number"}</DialogTitle>
        <DialogContent>
          <>
            <Box>
              <div className="f12-500">
                Enter table or Seat No available at you table
              </div>
              <div className="my-3">
                <TextField
                  label="Table /Seat Number"
                  value={selectedTable}
                  onChange={(e) => setSelectedTable(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
            </Box>

            <Typography fontSize={18} fontWeight={500} color="initial">
              {" Party Size"}
            </Typography>
            <div className="f12-500">
              Please enter the number of Guests dining in.
            </div>
            <Box my={2}>
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <Button
                  key={value}
                  onClick={() => handlePaxButtonRangeClick(value)}
                  variant={selectedPax === value ? "contained" : "outlined"}
                  color={selectedPax === value ? "error" : "info"}
                  sx={{ m: 1 }}
                >
                  {value}
                </Button>
              ))}
            </Box>
            <div className="f14-500 my-3"> Or </div>
            <TextField
              label="Enter Pax"
              type="number"
              value={selectedPax}
              onChange={handlePaxChange}
              inputProps={{ min: 1, max: 6 }}
              sx={{ width: "100%" }}
              error={selectedPax > maxPaxValue}
              helperText={
                selectedPax > maxPaxValue
                  ? `Maximum party size is ${maxPaxValue}  PAX.`
                  : ""
              }
            />
          </>

          <Box>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button
                onClick={handleCancel}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  color: "black",
                  border: "1px solid #C4C4C4",
                  textTransform: "capitalize",
                }}
                className="bg-white mr-2"
                fullWidth
              >
                cancel
              </Button>

              <Button
                onClick={handleProceed}
                disabled={selectedPax > maxPaxValue}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(252, 90, 71) !important",
                  textTransform: "capitalize",
                }}
                className="bg-danger ml-2"
                fullWidth
              >
                Submit
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderTrackScreen;
