import React, { useState, useRef } from 'react';
import { Calendar, Clock } from 'lucide-react';
import Icons from '../../../components/Icons';
import moment from 'moment';
import CustomDatePicker from '../../../components/datePickers/CustomDatePicker'
import { ImageConstants } from '../../../common/utility/ImageConstants';
import { Box, Typography, Button } from '@mui/material';
import { assets } from '../../../assets/assets';

const EditExpBookingDetails = ({
  TSData,
  formik
}) => {
  const [selectedTime, setSelectedTime] = useState('2:45 PM');
  const [selectedDate, setSelectedDate] = useState('');

  const [bookDate, setBookDate] = useState(
      // state?.actionBy === 'PAYMENT_SUMMARY'
      //   ? moment(getFilledExpData?.reservationDate)
      //   : moment()
      moment()
    );

  const dateInputRef = useRef(null);
  
  const timeSlots = TSData;

  const handleEditClick = () => {
    dateInputRef.current.showPicker();
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const datepickerDateAction = (date) => {
      let selectedDate = moment(date).format('YYYY-MM-DD');
      // setCurrentDate(selectedDate);
      // setBookDate(date);
    };

  return (
    <div className="max-w-2xl p-6 my-4">
      <h5 className="card-title mb-0" style={{ fontWeight: "bold" }}>
        Select your booking details
      </h5>
      
      {/* Date Selection */}
      <div className="my-3">
        <div className="d-flex align-items-center gap-2 text-gray-500 my-2">
          <Icons type="Exp_Calendar"/>
          <span>Select Date</span>
        </div>

        <CustomDatePicker
            value={bookDate}
            defaultValue={
              moment().format(
                    'dddd DD MMMM'
                  )
              // state?.actionBy === 'PAYMENT_SUMMARY'
              //   ? moment(getFilledExpData?.reservationDate).format(
              //       'dddd DD MMMM'
              //     )
              //   : moment(getFilledExpData?.reservationDate).format(
              //       'dddd DD MMMM'
              //     )
            }
            // minDate={moment(experienceData?.startDate)}
            //maxDate={moment(experienceData?.endDate)}
            onChange={(val) => datepickerDateAction(val)}
            borderRadius={2}
            //label={todat}
            restStyle={{ width: '100%' }}
            showEditIcon={false}
          />
      </div>

      {/* Time Selection */}
      <div className="my-3">
        <div className="d-flex align-items-center gap-2 text-gray-500 my-2">
          <Icons type="clock"/>
          <span>Select Time</span>
        </div>
        <div className="container justify-content-start p-0 py-2">
            <div className='col-md-6 col-lg-12 gap-3 '>
            {timeSlots?.map((time) => (
              <Button
                variant="contained"
                size="medium"
                onClick={() =>{
                  formik.setFieldValue('reservationTime', time)
                }}
                style={{
                  borderRadius: '12px',
                  color: 'black',
                  textTransform: 'capitalize',
                  backgroundColor:  assets.colors.white_400,
                  color:"black",
                  border: `1px solid ${time === formik?.values?.reservationTime ? assets.colors.primary: assets.colors.border_clr}`,

                }}
                className={`m-2`}
              >
                {time}
              </Button>
            ))}
            </div>
          </div>
      </div>

      {/* Warning Message */}
      <p className="text-sm text-gray-600">
        If your party side is more than the allowed limit per ticket, we recommed you proceed with the packages offered.
      </p>
    </div>
  );
};

export default EditExpBookingDetails;