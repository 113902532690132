export const FLAT_CHARGE_KEYS = [
  'take_away',
  'curb_side',
  'skip_line',
  'dine_in_order',
  'buffet',
  'catering_banquet',
  'drive_thru',
  'home_delivery',
];

export const ITEMTYPEVEG = [
  {
    id: 0,
    value: 1,
    title: 'Veg',
    url: 'https://amealia.s3.amazonaws.com/categoryicons/superadminCategoryicon-190-0003.png',
  },
  {
    id: 1,
    value: 2,
    title: 'Non Veg',
    // url: "https://amealia.s3.amazonaws.com/categoryicons/superadminCategoryicon-190-0002.png",
    url: 'https://amealia.s3.amazonaws.com/categoryicons/superadminCategoryicon-Non Veg.png',
  },
  {
    id: 2,
    value: 3,
    title: 'Egg',
    url: 'https://amealia.s3.amazonaws.com/categoryicons/superadminCategoryicon-190-0001.png',
  },
];

export const ORDER_TYPES = [
  'take_away',
  'curb_side',
  'skip_line',
  'dine_in',
  'buffet',
  'catering_banquet',
  'drive_thru',
  'home_delivery',
];

export const ORDER_TYPES_MAPPING = {
  TAKEAWAY: 0,
  CURBSIDE: 1,
  SKIPTHELINE: 2,
  DINEIN: 3,
  BUFFET: 4,
  CATERING: 5,
  DRIVETHRU: 6,
  HOMEDELIVERY: 7,
};

export const ORDER_TYPE_NAME = [
  'Takeaway',
  'Curbside',
  'Skip the Line',
  'Dine-In',
  'Buffet',
  'Catering',
  'Drivethru',
  'Delivery',
];

export const ORDER_STATUS = [
  'PENDING', //0
  'CONFIRMED', //1
  'PREPARING', //2
  'PACKING', //3
  'READYTOPICK', //4
  'ONTHEWAY', //5
  'DELIVERED', //6
  'COMPLETED', //7
  'CANCELLED', //8
  '',
  'RETURNED',
];

export const vechileTypes = [
  {
    id: 4,
    title: '4-wheeler',
    icon: require('../../assets/images/ordericon/car.svg'),
  },
  {
    id: 2,
    title: '2-wheeler',
    icon: require('../../assets/images/ordericon/scooter.svg'),
  },
  {
    id: 3,
    title: '3-wheeler',
    icon: require('../../assets/images/ordericon/rikshaw.svg'),
  },
  {
    id: 0,
    title: 'Others',
    icon: require('../../assets/images/ordericon/taxi.svg'),
  },
];

export const CAR_COLORS = [
  { name: 'White', value: '#FFFFFF' },
  { name: 'Silver', value: '#C0C0C0' },
  { name: 'Black', value: '#000000' },
  { name: 'Gray', value: '#808080' },
  { name: 'Blue', value: '#0000FF' },
  { name: 'Red', value: '#FF0000' },
  { name: 'Brown', value: '#964B00' },
  { name: 'Green', value: '#00FF00' },
  { name: 'Yellow', value: '#FFFF00' },
  { name: 'Beige', value: '#F5F5DC' },
  { name: 'Gold', value: '#FFD700' },
  { name: 'Orange', value: '#FFA500' },
  { name: 'Purple', value: '#800080' },
];

// export const OrderTrackScreenMsgs = [
//   {status:}
// ]
export const PAYMENT_STATUS = [
  'pending',
  'paid',
  'cancelled',
  'failure',
  'REVERSED',
];

export const PAYMENTMETHODARR = {
  CASH: 0,
  UPI: 1,
  PAYTM: 2,
  DEBITCARD: 3,
  CREDITCARD: 4,
  NETBANKING: 5,
  PAYLATER: 6,
  WALLET: 7,
  EXTERNAL: 8,
  INCASH: 9,
  SPLIT: 10,
};

export const NEW_PAYMENT_TYPE = [
  'COD',
  'UPI',
  'Paytm',
  'Debit Card',
  'Credit Card',
  'NetBanking',
  'Pay Later',
  'Wallet',
  'External Wallet',
  'Cash',
];

export const NEW_PAYMENT_TYPE_METHOD = [
  'COD',
  'BHIM UPI',
  'Paytm',
  'Debit Card',
  'Credit Card',
  'NETBANKING',
  'Pay At Site',
  'WALLET',
  'External Wallet',
  'Cash',
];

export const foodServicesFilters = [
  'HOME_DELIVERY',
  'DINE_IN',
  'CURB_SIDE',
  'SKIP_THE_LINE',
  'TAKE_AWAY',
  'WALK_IN',
  'RESERVATION',
  'WAIT_LIST',
];
