import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Avatar,
  Modal,
  TextField,
  Badge,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import assets from '../../assets/assets';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { setLocation } from '../../store/slices/locationSlice';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsDrawer from '../notifications/MainNotificationsDrawer';
import { selectUnreadCount } from '../../store/slices/notificationSlice';
import useNotificationsApi from '../../api/notificationsApis';

const MainHeader = ({
  currentLocation,
  disabled,
  updateLocation,
  showLocation = false,
  isDesktop,
  sidebarWidth = 240,
  setCurrentLocation,
  setLocationState,
}) => {
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userDetails);
  const { fetchNotifications } = useNotificationsApi();
  const unreadCount = useSelector(selectUnreadCount);

  const TruncatedText = ({ text, textLength }) => {
    const maxLength = textLength;
    const truncatedText =
      text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return truncatedText;
  };

  useEffect(() => {
    if (!searchQuery || !window.google) return;
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: searchQuery },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setLocationSuggestions(predictions || []);
        } else {
          setLocationSuggestions([]);
          console.error('AutocompleteService Error:', status);
        }
      }
    );
  }, [searchQuery]);

  const handleLocationSelect = (location) => {
    setCurrentLocation(location.description);
    setLocationModalOpen(false);
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    placeService.getDetails({ placeId: location.place_id }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        // saveLocationToLocalStorage(lat, lng, location.description);
        updateLocation(lat, lng, location.description);
        setLocationState({
          lat: lat,
          lng: lng,
          locationName: location.description,
        });
        dispatch(
          setLocation({
            lat: lat,
            lng: lng,
            locationName: location.description,
          })
        );
        localStorage.setItem(
          'userLocation',
          JSON.stringify({
            lat: lat,
            lng: lng,
            locationName: location.description,
          })
        );
      } else {
        console.error('Place Details Error:', status);
      }
    });
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (userData) {
      fetchNotifications(userData?._id, dispatch);
    }
  }, [userData]);

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        width: isDesktop ? `calc(100% - ${sidebarWidth}px)` : '100%',
        margin: 0,
        ml: isDesktop ? `${sidebarWidth}px` : 0,
        zIndex: 1100,
        backgroundColor: 'white',
      }}
    >
      <Toolbar>
        {showLocation && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => !disabled && setLocationModalOpen(true)}
          >
            {/* Location Section */}
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
              <FmdGoodIcon sx={{ color: !disabled ? '#EE3A23' : '#8A8A8A' }} />
              <div className="w-full">
                <Typography
                  className={`text-lg font-Mulish font-semibold ${
                    !disabled ? 'text-[#000000]' : 'text-[#8A8A8A]'
                  }`}
                >
                  <TruncatedText text={currentLocation} textLength={17} />
                </Typography>
                <span className="text-[10px] md:text-sm  font-Mulish font-semibold text-[#8A8A8A]">
                  Set or change your location
                </span>
              </div>
            </Box>
            {/* <Typography
              variant="body2"
              sx={{
                color: "gray",
                mt: 0.5,
                textAlign: "left",
                width: "100%",
              }}
            >
              Set or change your location
            </Typography> */}
          </Box>
        )}

        <Box display="flex" alignItems="center" sx={{ ml: 'auto' }}>
          <IconButton
            color="inherit"
            className="w-6 md:w-12 h-6 md:h-12"
            sx={{
              border: {
                xs: 'none',
                sm: 'none',
                md: '1px solid #EFEFEF',
                lg: '1px solid #EFEFEF',
              },
            }}
            onClick={toggleDrawer}
          >
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Avatar
            alt="Profile Picture"
            src="/static/images/avatar/1.jpg"
            sx={{ ml: 3 }}
          />
        </Box>
      </Toolbar>
      <NotificationsDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        isDesktop={isDesktop}
      />

      {showLocation && (
        <Modal
          open={isLocationModalOpen}
          onClose={() => setLocationModalOpen(false)}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: '80%',
              maxWidth: 400,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Search Location
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter location"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box>
              {locationSuggestions.length > 0 ? (
                locationSuggestions.map((location, index) => (
                  <Typography
                    key={index}
                    sx={{ cursor: 'pointer', mb: 1 }}
                    onClick={() => handleLocationSelect(location)}
                  >
                    {location.description}
                  </Typography>
                ))
              ) : (
                <Typography sx={{ color: 'gray', textAlign: 'center' }}>
                  No suggestions available
                </Typography>
              )}
            </Box>
          </Box>
        </Modal>
      )}
    </AppBar>
  );
};

MainHeader.propTypes = {
  currentLocation: PropTypes.object,
  disabled: PropTypes.bool,
  updateLocation: PropTypes.func,
  showLocation: PropTypes.bool,
  isDesktop: PropTypes.bool,
  sidebarWidth: PropTypes.number,
  setCurrentLocation: PropTypes.func,
  setLocationState: PropTypes.func,
};

export default memo(MainHeader);
