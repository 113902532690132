import React from 'react';
import { Check } from 'lucide-react';
import { Button, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FoodOrderCard from './FoodOrderCard';
import Failure from '../../assets/images/IconsandImages/Failure.png'

const FoodPaymentPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // const orderData = JSON.parse(sessionStorage.getItem('orderPostData'));
  // const orderType = sessionStorage.getItem('cartOrderType');



  // const formattedTime = date.toLocaleString('en-US', {
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   hour12: true,
  // });
  // const formattedDate = date.toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric',
  // });

  // const finalFormat = `${formattedTime} | ${formattedDate}`;

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white">
      {/* Header */}
      <div className="mb-2">
        <h4 className="text-danger" style={{ fontWeight: 'bold',color:"red",fontSize:"30px" }}>
          Order Details
        </h4>
      </div>

      {/* Payment Success Message */}
      <div className="text-center py-8 bg-green-50 rounded-lg mb-8">
        <div className="d-flex justify-content-center mb-3">
          <div
            className="rounded-circle p-1 d-flex align-items-center justify-content-center"
            style={{ width: '5rem', height: '5rem' }}
          >
            <Image
              src={Failure}
              width={'100%'}
              className="object-contains w-100 rounded-4"
            />
          </div>
        </div>

        <p
          className="p-0 m-3"
          style={{ fontWeight: 'bold', fontSize: '1.3em',color:"red" }}
        >
          Payment Failed
        </p>
        <p
          className="p-0 m-1"
          style={{ fontWeight: 'bold', fontSize: '1.0em' }}
        >
          Your payment is Failed!
        </p>
        <p className="p-0 m-1" style={{ fontSize: '0.8em' }}>
          Transaction ID: 
        </p>
        <p className="p-0 m-1" style={{ fontSize: '0.8em' }}>
          
        </p>
      </div>

      {/* Order Details Section */}
      <div className="mb-3">
        <h5 className="" style={{ fontWeight: 'bold' }}>
          Order Details
        </h5>
      </div>
       <FoodOrderCard />

      {/* Payment Details Section */}
      <div className="w-100 mt-4 d-flex justify-content-between align-items-start">
  {/* Left Section (Placeholder or other content) */}
  <div className="flex-grow-1">
    {/* You can add any content or leave it blank */}
  </div>

  {/* Payment Details Section */}
  <div className="h-100" style={{ width: '40%' }}>
    <h5 className="" style={{ fontWeight: 'bold' }}>
      Payment Details
    </h5>

    <div className="d-flex align-items-center justify-content-between">
      <p className="p-0 m-0" style={{ fontSize: '0.9em' }}>
        Sub Total
      </p>
      <h5 className="" style={{ fontWeight: 'bold' }}>
        ₹ 
      </h5>
    </div>

    <div className="d-flex align-items-center justify-content-between">
      <Button
        variant="outlined"
        size="medium"
        onClick={() => alert('Rate feature coming soon!')}
        style={{
          borderRadius: '12px',
          textTransform: 'capitalize',
          color: 'black',
          border: `1px solid black`,
          width: '40%',
        }}
        className="my-2"
      >
        Track
      </Button>

      <Button
        variant="contained"
        size="medium"
      
        style={{
          borderRadius: '12px',
          textTransform: 'capitalize',
          backgroundColor: 'red',
          color: 'white',
          border: `1px solid `,
          width: '40%',
        }}
        className="my-2 px-5"
      >
        Rate Us
      </Button>
    </div>
  </div>
</div>

    </div>
  );
};

export default FoodPaymentPage;
