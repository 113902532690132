import StarRateIcon from "@mui/icons-material/StarRate";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  arrayToString,
  getExperienceTime,
  getMinimumAmountFromPackageExperience,
  isEmpty,
} from "../../common/utility/helperFunc";
import { RWebShare } from "react-web-share";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import { AnalyticPage } from "../../common/enums/GlobalEnums";
import { Avatar } from "@mui/material";
import {
  getDiscountedAmount,
  getExperimentTime,
  getMinimumAmount,
} from "../../common/utility/expFunctions";
//

export const ExperienceGrid = ({ data }) => {
  //console.log("ExperienceGrid", data);
  const shareData = {
    title: "title",
    text: `I found this great place  on Amealio, with this app I save time and money at my convenience. You can Discover, explore, engage order and much more on this platform seamlessly. `,
    url: ``,
  };

  // eslint-disable-next-line
  const [
    pageStatscancel,
    pageStatsresponseData,
    pageStatserror,
    pageStatsloaded,
    pageStatsreset,
    pageStatsexecuteAPI,
  ] = useAxios();

  const handleClickcount = () => {
    if (sessionStorage.getItem("BroserId")) {
      const params = {
        deviceType: "",
        page: AnalyticPage.EXP_VIEW,
        deviceId: sessionStorage.getItem("BroserId"),
        app: "WEB_APP",
      };
      pageStatsexecuteAPI(
        `${URL.PAGESTATS}?restaurantId=${data?.restaurantId?._id}`,
        "POST",
        params
      );
    }
  };

  const navigate = useNavigate();

  const getRestaurantCuisine = (data) => {
    let expData = !isEmpty(data) && data?.restCuisines;
    return expData;
  };

  return (
    <div className="f20-500 h-100 w-100 mt-4">
      <div
        className="experience-card overlay shadow-sm"
        style={{
          borderRadius: "19px",
          background: `url('${data?.photos?.[0] || data?.photos?.[0]}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="d-flex justify-content-between align-items-center p-4">
          <div className="bg-white rounded-circle px-2 text-center">
            {/* <RWebShare
              data={shareData}
              onClick={() => console.log("shared successfully!")}
            >   
                <Image
                  src={require("../../assets/images/ExperiencePageAssets/share.png")}
                  alt="icon"
                  width="16px"
                  height="16px"
                  style={{
                marginTop: "-4px",
                marginLeft: "-2px",
              }}
                />
       
            </RWebShare> */}
            <Image
              className="icon"
              src={require("../../assets/images/ExperiencePageAssets/share.png")}
              alt="Share"
              title="Share"
              style={{
                marginTop: "-4px",
                marginLeft: "-2px",
              }}
            />
          </div>
          <div className="bg-white rounded-circle px-2 text-center">
            <Image
              className="icon"
              src={require("../../assets/images/ExperiencePageAssets/camera.png")}
              alt="Camera"
              title="Camera"
              style={{
                marginTop: "-4px",
              }}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="experience-card-content p-3">
            <div className="row">
              <div className="col-2">
                <Avatar
                  alt="Remy Sharp"
                  src={data?.restaurantId?.logo_url_thumbnails}
                />
              </div>
              <div className="col-10">
                <div className="f20-700" style={{ color: "#ffffff" }}>
                  {data?.restaurantName.length > 32
                    ? data?.restaurantName.substring(0, 32) + "..."
                    : data?.restaurantName}
                </div>
                <div className="f14-500" style={{ color: "#ffffff" }}>
                  {getRestaurantCuisine(data).length > 43
                    ? getRestaurantCuisine(data).substring(0, 46) + "..."
                    : getRestaurantCuisine(data)}
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-6 ">
                <div className="f15-500">{data?.name || ""}</div>
              </div>
              <div className="col-6">
                <div className="view-rate-count shadow-sm">
                  <div className="boxStyleIcons">
                    <Image
                      alt="view"
                      src={require("../../assets/images/ExperiencePageAssets/eye.png")}
                      style={{
                        width: 12,
                        height: 8,
                        margin: "0 auto",
                        marginBottom: 2,
                      }}
                    />
                    <div className="f8-400 text-dark text-center">
                      {data?.expViews} views
                    </div>
                  </div>
                  <div className="verticleLine"></div>
                  <div className="boxStyleIcons">
                    <Image
                      alt="view"
                      src={require("../../assets/images/ExperiencePageAssets/star.png")}
                      style={{
                        width: 10,
                        height: 8,
                        margin: "0 auto",
                        marginBottom: 2,
                      }}
                    />
                    <div className="f8-400 text-dark text-center">
                      {data?.expReviewed}
                    </div>
                  </div>
                  <div className="verticleLine"></div>
                  <div className="boxStyleIcons">
                    <Image
                      alt="view"
                      src={require("../../assets/images/ExperiencePageAssets/heartColoured.png")}
                      style={{
                        width: 9,
                        height: 8,
                        margin: "0 auto",
                        marginBottom: 2,
                      }}
                    />
                    <div className="f8-400 text-dark text-center">
                      {data?.expUsed}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex align-items-center">
              <div>
                <div className="f14-500">{data?.name || ""}</div>
                <div className="f10-400">
                  {arrayToString(data?.classification, "|", "title")}
                </div>
              </div>
              <div className="ml-3">
                <div className="d-flex align-items-center f12-500">
                  <StarRateIcon style={{ color: "green" }} className="mr-1" />
                  {data?.expReviewed || 0}
                </div>
                <div style={{ color: "#EBA22E" }} className="f12-400">
                  {data?.expReviewed || 0} Reviews
                </div>
              </div>
            </div> */}
            <div className="row">
              {/****** Distance ******/}
              {/* <div className="col-4 pl-0">
                  <Avatar
                    alt="Rupees"
                    src={require("../../assets/images/ExperiencePageAssets/rupee.png")}
                    sx={{ width: 18, height: 18 }}
                  />
                  <div className="f11-400">
                  {getSpecificDistance(item, getLocation, "experience")}
                  </div>
              </div> */}
              {/****** Time Mange ******/}
              <div className="col-6 d-flex align-items-center">
                <Image
                  alt="Time"
                  src={require("../../assets/images/ExperiencePageAssets/clock.png")}
                  style={{ width: 16, height: 18 }}
                  className="img-responsive"
                />
                <div className="f12-400 mx-2">
                  {getExperimentTime(data?.timings)}
                </div>
              </div>
              {/****** Price ******/}
              <div className="col-6">
                <div className="row align-items-center">
                  <div className="col-2 d-flex align-items-center p-0">
                    <Image
                      alt="Rupees"
                      src={require("../../assets/images/ExperiencePageAssets/rupee.png")}
                      style={{ width: 18, height: 18 }}
                      className="img-responsive"
                    />
                  </div>
                  <div className="col-10 d-flex align-items-center p-0">
                    {getDiscountedAmount(data) === 0 ? (
                      <></>
                    ) : (
                      <div className="f12-400 text-decoration-line-through">
                        ₹{getDiscountedAmount(data)}
                      </div>
                    )}
                    <div className="f12-400 d-flex align-items-center mx-2">
                      ₹{getMinimumAmount(data)}
                      <div className="f11-400 ml-1">onwards.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-danger py-2 my-2 text-center w-100 text-white f16-500"
              style={{ borderRadius: "12px", backgroundColor: "#F85032" }}
              onClick={() => {
                navigate(
                  `/restaurant/${data?.restaurantId?._id}/experience/${data?._id}`
                );
                handleClickcount();
              }}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
