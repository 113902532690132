import React, { useState, useCallback, useEffect } from 'react';
import HomePageSearchBar from '../../components/SearchBar/HomeSearchBar';
import HomeFilter from '../homePage/HomeFilter';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import ExperienceBanner from '../homePage/ExperienceBanner';
import ReferEarnBanner from '../ReusableComponent/ReferAndEarn';
import ScreenTemplate from '../../components/ScreenTemplate';
import MainSideBar from '../../components/MainsideBar';
import MainHeader from '../../components/headers/Mainheader';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import useLocation from '../../common/hooks/useLocation';
import useApi from '../../common/api/hooks/useApi';
import { GOOGLE_API_KEY } from '../../config/keys';
import { URL } from '../../common/api/urls';
import OrderingServices from '../../components/food/OrderingServices';
import { Col, Row, Image } from 'react-bootstrap';
import { useAxios } from '../../common/api/hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from '../../setup/socket/SocketContext';
import {
  clearOndcRestaurants,
  saveOndcRestaurants,
} from '../../store/slices/ondcSlice';
import OndcSeachBar from './Components/ondcSearchBar';

const ALL_CATEGORY_KEYS = {
  RESTAURANT_TYPE: '5f562c9094ac7fdf47ea1d0a',
  FOOD_TYPE: '5f562c9094ac7fdf47ea1d1d',
  LIQUORS: '5f562c9094ac7fdf47ea1d1c',
};

const OndcRestaurantsList = (props) => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const {
    coordinates,
    currentLocation,
    setCurrentLocation,
    updateLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.user.userDetails);
  const [cancel, ondcRestData, error, loaded, reset, executeOndcRestApi] =
    useAxios();
  const ondcRestaurants = useSelector((state) => state.ondc.ondcRestaurants);

  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRestaurants(ondcRestaurants);
  }, [ondcRestaurants]);

  const fetchOndcRestaurants = async () => {
    try {
      dispatch(clearOndcRestaurants());

      const currentLatitude = location?.lat;
      const currentLongitude = location?.lng;
      // const currentLatitude = 12.972166
      // const currentLongitude = 77.595138

      let formData = {
        intent: {
          fulfillment: {
            id: '12323123',
            type: 'Delivery',
            end: {
              location: {
                circle: {
                  gps: `${currentLatitude}, ${currentLongitude}`,
                  radius: {
                    unit: 'km',
                    value: '1000',
                  },
                },
              },
            },
          },
          payment: {
            [`@ondc/org/buyer_app_finder_fee_type`]: 'percent',
            [`@ondc/org/buyer_app_finder_fee_amount`]: '3',
          },
        },
      };

      const headers = {
        'Content-Type': 'application/json',
        _id: userAuth.User?._id ? userAuth.User?._id : userAuth?._id,
      };

      const response = await executeOndcRestApi(
        `${URL.GET_ONDC_RESTAURNATS}`,
        'POST',
        formData,
        headers,
        (response) => {}
      );
    } catch (error) {
      console.log('ONDC-RESTAURANTS-LIST-ERROR', error);
    }
  };

  useEffect(() => {
    fetchOndcRestaurants();
  }, [location]);

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        userAuth.accessToken
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log('Socket Re-Authenticated');
        })
        .catch((e) => {
          console.log('error', e);
        });
    }
    const ondcSocket = props.socket.service('ondc');
    console.log('ondcSocket===>130');
    ondcSocket.on('ondc_on_search', (data) => {
      console.log('ondcSocket===>132', data);
      const restaurants = data?.message?.catalog?.['bpp/providers'];

      dispatch(saveOndcRestaurants(restaurants));
    });
  }, []);

  const restaurantsToRender = restaurants.map((rest) => {
    return {
      ...rest,
      logo_url: rest?.descriptor?.images[0],
      restaurant_name: rest?.descriptor?.name,
      restaurant_description: rest?.descriptor?.long_desc,
      _id: rest?.id,
    };
  });

  function onSearch(value) {
    const filteredRest = ondcRestaurants.filter((rest) => {
      const name = rest?.descriptor?.name?.toLowerCase();
      const regex = new RegExp(value.toLowerCase(), 'i'); // "i" flag makes it case-insensitive
      return name && regex.test(name);
    });

    setRestaurants(filteredRest);
  }

  const content = (
    <>
      <OndcSeachBar onChange={onSearch} />
      <div
        className={`d-flex ${isDesktop ? 'flex-row' : 'flex-column'} ${
          isDesktop ? '' : 'justify-content-center align-items-center'
        }`}
        style={{
          gap: '20px',
          marginTop: '15px',
        }}
      >
        <div>
          <div className="f22-700 ml-1 mb-4">Neighboring Restaurants</div>
          <RestaurantCard restaurants={restaurantsToRender} from="ONDC" />
          {isLoading && <div>Loading more restaurants...</div>}
        </div>
      </div>

      <div className="mt-4 " style={{ backgroundColor: '#F4F3FE' }}>
        <div
          className="d-flex justify-content-center align-items-center f24-500 "
          style={{ gap: '1px' }}
        >
          <span>With</span>
          <Image
            src={require('../../assets/images/ExperiencePageAssets/love-location.png')}
            alt="Love"
            title="Love"
            width={300}
            style={{ marginLeft: '-75px', marginRight: '-75px' }}
          />
          <span>Amealio</span>
        </div>
      </div>
    </>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
};

const OndcRestaurantsMain = (props) => (
  <SocketContext.Consumer>
    {(socket) => <OndcRestaurantsList {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default OndcRestaurantsMain;
