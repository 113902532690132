import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../store/slices/locationSlice";

const useLocation = (GOOGLE_API_KEY) => {
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [currentLocation, setCurrentLocation] = useState("Loading...");

  const [location, setLocationState] = useState({
    lat: "",
    lng: "",
    locationName: "",
  });
  const savedLocation = useSelector((state) => state.location.value);
  const dispatch = useDispatch();

  const extractMainText = (addressComponents) => {
    return (
      addressComponents.find((component) =>
        component.types.includes("sublocality")
      )?.long_name ||
      addressComponents.find((component) => component.types.includes("route"))
        ?.long_name ||
      addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name ||
      "Unknown Location"
    );
  };

  // useEffect(() => {
  //   if (savedLocation === undefined || savedLocation === null) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setCoordinates({ lat: latitude, lng: longitude });
  //         fetch(
  //           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
  //         )
  //           .then((response) => response.json())
  //           .then((data) => {
  //             if (data.results && data.results.length > 0) {
  //               const mainText = extractMainText(
  //                 data.results[0].address_components
  //               );
  //               setCurrentLocation(mainText);
  //               setLocationState({
  //                 lat: position.coords.latitude,
  //                 lng: position.coords.longitude,
  //                 locationName: mainText,
  //               });
  //               dispatch(
  //                 setLocation({
  //                   lat: position.coords.latitude,
  //                   lng: position.coords.longitude,
  //                   locationName: mainText,
  //                 })
  //               );
  //               localStorage.setItem(
  //                 "userLocation",
  //                 JSON.stringify({
  //                   lat: position.coords.latitude,
  //                   lng: position.coords.longitude,
  //                   locationName: mainText,
  //                 })
  //               );
  //             } else {
  //               setCurrentLocation("Unknown Location");
  //               setLocationState({
  //                 lat: null,
  //                 lng: null,
  //                 locationName: "Unknown Location",
  //               });
  //               dispatch(
  //                 setLocation({
  //                   lat: null,
  //                   lng: null,
  //                   locationName: "Unknown Location",
  //                 })
  //               );
  //               localStorage.setItem(
  //                 "userLocation",
  //                 JSON.stringify({
  //                   lat: null,
  //                   lng: null,
  //                   locationName: "Unknown Location",
  //                 })
  //               );
  //             }
  //           })
  //           .catch(() => {
  //             setCurrentLocation("Unknown Location");
  //             setLocationState({
  //               lat: null,
  //               lng: null,
  //               locationName: "Unknown Location",
  //             });
  //             dispatch(
  //               setLocation({
  //                 lat: null,
  //                 lng: null,
  //                 locationName: "Unknown Location",
  //               })
  //             );
  //             localStorage.setItem(
  //               "userLocation",
  //               JSON.stringify({
  //                 lat: null,
  //                 lng: null,
  //                 locationName: "Unknown Location",
  //               })
  //             );
  //           });
  //       },
  //       () => {
  //         setCurrentLocation("Permission Denied");
  //         setLocationState({
  //           lat: null,
  //           lng: null,
  //           locationName: "Permission Denied",
  //         });
  //         dispatch(
  //           setLocation({
  //             lat: null,
  //             lng: null,
  //             locationName: "Permission Denied",
  //           })
  //         );
  //         localStorage.setItem(
  //           "userLocation",
  //           JSON.stringify({
  //             lat: null,
  //             lng: null,
  //             locationName: "Permission Denied",
  //           })
  //         );
  //       }
  //     );
  //   }
  // }, [savedLocation]);

  useEffect(() => {
    // Check if location is already saved in localStorage
    const storedLocation = localStorage.getItem("userLocation");
    if (storedLocation) {
      const parsedLocation = JSON.parse(storedLocation);
      setLocationState({
        lat: parsedLocation.lat,
        lng: parsedLocation.lng,
        locationName: parsedLocation.locationName,
      });
      setCurrentLocation(parsedLocation.locationName);
      dispatch(setLocation(parsedLocation));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ lat: latitude, lng: longitude });
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                const mainText = extractMainText(
                  data.results[0].address_components
                );
                setCurrentLocation(mainText);
                setLocationState({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  locationName: mainText,
                });
                dispatch(
                  setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    locationName: mainText,
                  })
                );
                localStorage.setItem(
                  "userLocation",
                  JSON.stringify({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    locationName: mainText,
                  })
                );
              } else {
                setCurrentLocation("Unknown Location");
              }
            })
            .catch(() => setCurrentLocation("Unknown Location"));
        },
        () => setCurrentLocation("Permission Denied")
      );
    }
  }, [GOOGLE_API_KEY]);

  const updateLocation = (latitude, longitude, description = null) => {
    setCoordinates({ lat: latitude, lng: longitude });
    // Use description directly if provided
    if (description) {
      setCurrentLocation(description);
      return;
    }
    // Otherwise, perform reverse geocoding
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const mainText = extractMainText(data.results[0].address_components);
          setCurrentLocation(mainText);
          setLocationState({
            lat: latitude,
            lng: longitude,
            locationName: mainText,
          });
          dispatch(
            setLocation({
              lat: latitude,
              lng: longitude,
              locationName: mainText,
            })
          );
          localStorage.setItem(
            "userLocation",
            JSON.stringify({
              lat: latitude,
              lng: longitude,
              locationName: mainText,
            })
          );
        }
      })
      .catch(() => setCurrentLocation("Unknown Location"));
  };

  return {
    coordinates,
    currentLocation,
    setCurrentLocation,
    updateLocation,
    location,
    setLocationState,
  };
};

export default useLocation;
