import React from 'react';
import chaticon from '../../assets/images/IconsandImages/chaticon.png';
import helpicon from '../../assets/images/IconsandImages/helpicon.png';
import share from '../../assets/images/IconsandImages/share.png';
import referearn from '../../assets/images/IconsandImages/referearn.png';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { assets } from '../../assets/assets';
import { useNavigate, useParams } from 'react-router-dom';

const Exppassdetails = ({ trackData, experienceDetails }) => {
  const pack = trackData?.pack || 'Pack not available';
  const adults = trackData?.adults || 'N/A';
  const price = trackData?.price || 'N/A';
  const includes = 'N/A';
  const passNumber = trackData?.pass_number || 'N/A';
  const lastChangeDate = trackData?.last_change_date || 'N/A';
  const orderStatus = trackData?.status || 'Pending';

  const navigate = useNavigate();
  const { restaurantId, experienceId } = useParams();

  return (
    <>
      <div className="exppassdetails-container">
        <div className="d-flex align-items-center justify-content-between">
          <h2>Experience Pass Details</h2>
        </div>

        {/* Pack Details Section (Maintaining the Same Logic) */}
        <div className="pack-details">
          <div className="pack-info">
            <p>
              {trackData?.package?.count
                ? trackData?.package?.count + trackData?.package?.name
                : trackData?.experienceId?.name}
            </p>
            {trackData?.adultCount ? (
              <p>{trackData?.adultCount} Adults</p>
            ) : null}
            {trackData?.kidsCount ? <p>{trackData?.kidsCount} Kids</p> : null}
            {trackData?.package?.count ? (
              <div className="f12-500 d-flex text-indigo">
                <div>
                  {'('}
                  {trackData?.package?.adults
                    ? trackData?.package?.adults + ' Adults'
                    : null}{' '}
                  {', '}
                  {trackData?.package?.kids
                    ? trackData?.package?.kids + ' Kids'
                    : null}
                  {')'}
                </div>
              </div>
            ) : null}
          </div>

          {/* Correct Price Logic */}
          <div className="price">
            {trackData?.package?.count ? (
              '₹ ' + trackData?.package?.price
            ) : (
              <>
                {trackData?.adultCount ? (
                  <div className="f14-700">₹ {trackData?.adultPrice}</div>
                ) : null}

                {trackData?.kidsCount ? (
                  <div className="f14-700">₹ {trackData?.kidsPrice}</div>
                ) : null}
              </>
            )}
          </div>
        </div>

        {/* Includes Section */}
        <div className="includes">
          <p>Including:</p>
          <p>{includes}</p>
        </div>

        {/* Experience Details Section */}
        <div className="experiencedetail">
          <p>Experience Details:</p>
          <p>Seatnumbers:</p>
          <h4 className="right-text">H1/H2/H3/H4</h4>
        </div>

        <div className="experience-details">
          <h3>Experience Details</h3>
          <div className="detail-row">
            <div className="icons"></div>
            <div className="detail-left">
              <p className="detail-text">
                ₹ {trackData?.paymentData?.totalAmount}
              </p>
              <span className="sub-text">Seating Preference</span>
            </div>
            <div className="detail-right">
              <p className="detail-text">PENDING</p>
              <span className="sub-text">Payment Method</span>
            </div>
          </div>
          <div className="detail-row">
            <div className="icons"></div>
            <div className="detail-left">
              <p className="detail-text">{passNumber}</p>
              <span className="sub-text">Pass number</span>
            </div>
            <div className="detail-right">
              <p className="detail-text">{orderStatus}</p>
              <span className="sub-text">Status</span>
            </div>
          </div>
          <div className="detail-row">
            <div className="icons"></div>
            <div className="detail-left">
              <p className="detail-text">Corporate</p>
              <span className="sub-text">Special occasion</span>
            </div>
            <div className="detail-right">
              <p className="detail-text">{adults}</p>
              <span className="sub-text">Party size</span>
            </div>
          </div>
          <div className="detail-row">
            <div className="icons"></div>
            <div className="detail-left">
              <p className="detail-text">
                {moment(trackData?.reservationTime).format('DD MMM YYYY')}
              </p>
              <span className="sub-text">Last change date</span>
            </div>
            <div className="detail-right">
              <p className="detail-text">
                {moment(trackData?.reservationTime).format('hh:mm A')}
              </p>
              <span className="sub-text">Last change time</span>
            </div>
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="footer-buttons">
          <button className="chat-button">
            <img src={chaticon} alt="Chat" />
            Chat
          </button>
          <button className="help-button">
            <img src={helpicon} alt="Help" />
            Help
          </button>
          <button className="share-button">
            <img src={share} alt="Share" />
            Share
          </button>
        </div>

        <div className='mt-3'>
          <Button
          variant="contained"
          size="medium"
          onClick={() => {
            // formik.handleSubmit();
            // /restaurant/:ID/food/menu

            if(experienceDetails?.exp_order_id?._id){
              navigate(`/restaurant/${restaurantId}/food/ordertrack/${experienceDetails?.exp_order_id?._id}`);
            }else{
              navigate(`/restaurant/${restaurantId}/food/menu`, {
                state:{
                  from:"EXPERIENCE"
                }
              })
            }

          }}
          style={{
            borderRadius: "12px",
            color: "black",
            textTransform: "capitalize",
            backgroundColor: assets.colors.primary,
            color: "white",
            border: `1px solid ${assets.colors.primary}`,
            // width:"40%"
          }}
          className="my-2 px-5"
        >
          {experienceDetails?.exp_order_id?._id ? "View Order": "Add Items"}
        </Button>
      </div>

       {/* Image Section */}
        <div className="imagesection">
            <img src={referearn} alt="Event" className="eventimage" />
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default Exppassdetails;
