import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AmealioLogo from './reusable/AmealioLogo';

// Import your SVG icons
import HomeIcon from '../assets/images/ordericon/HomeIcon.svg';
import ExpIcon from '../assets/images/ordericon/ExpIcon.svg';
import CommunityIcon from '../assets/images/ordericon/CommunityIcon.svg';
import FoodIcon from '../assets/images/ordericon/FoodIcon.svg';
import BytesIcon from '../assets/images/ordericon/BytesIcon.svg';

const iconMap = {
  Home: <img src={HomeIcon} alt="Home" style={{ width: '24px', height: '24px' }} />,
  Experience: <img src={ExpIcon} alt="Experience" style={{ width: '24px', height: '24px' }} />,
  Community: <img src={CommunityIcon} alt="Community" style={{ width: '24px', height: '24px' }} />,
  Food: <img src={FoodIcon} alt="Food" style={{ width: '24px', height: '24px' }} />,
  Bytes: <img src={BytesIcon} alt="Bytes" style={{ width: '24px', height: '24px' }} />,
};

const MainSideBar = ({ windowWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { label: 'Home', route: '/welcome', icon: 'Home' },
    { label: 'Experience', route: '/experience', icon: 'Experience' },
    { label: 'Community', route: '/community', icon: 'Community' },
    { label: 'Food', route: '/food', icon: 'Food' },
    { label: 'Ondc', route: '/ondc', icon: 'Food' },
    { label: 'Bytes', route: '/bytes', icon: 'Bytes' },
  ];

  const getActiveItem = () => {
    const activeItem = navItems.find((item) =>
      location.pathname.includes(item.route)
    );
    return activeItem ? activeItem.label : '';
  };

  const activeItem = getActiveItem();

  if (windowWidth > 1024) {
    return (
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            color: '#000000',
            borderRight: '1px solid #e0e0e0',
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <AmealioLogo />
        </Box>
        <List>
          {navItems.map((item) => (
            <ListItem key={item.label}>
              <ListItemButton
                selected={activeItem === item.label}
                onClick={() => navigate(item.route)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#FFB240',
                    borderRadius: '12px',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#FF7043',
                    },
                  },
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: activeItem === item.label ? '#FFFFFF' : '#757575',
                  }}
                >
                  {iconMap[item.icon]}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF',
        borderTop: '1px solid #e0e0e0',
        zIndex: 1000,
      }}
    >
      <List
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          padding: '8px 0',
        }}
      >
        {navItems.map((item) => (
          <ListItem key={item.label} sx={{ padding: 0 }}>
            <ListItemButton
              selected={activeItem === item.label}
              onClick={() => navigate(item.route)}
              sx={{
                '&.Mui-selected': {
                  color: '#FFB240',
                },
                '&:hover': {
                  color: '#FF7043',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: activeItem === item.label ? '#FFB240' : '#757575',
                }}
              >
                {iconMap[item.icon]}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MainSideBar;
