import React, { useState } from 'react';
import { Theater } from 'lucide-react';
import { assets } from '../../../assets/assets';
import { Box, Typography, Button } from '@mui/material';
import Icons from '../../../components/Icons';
import { useLocation } from 'react-router-dom';

const SpecialOccasions = ({formik, onCancel=()=>{}}) => {
  const [occasion, setOccasion] = useState('');
  const { state } = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Special occasion:', occasion);
  };

  const handleCancel = () => {
    setOccasion('');
  };

  return (
    <div className="max-w-2xl py-4">
      <div className="d-flex align-items-center gap-2 mb-4">
        {/* <Theater className="w-6 h-6 text-red-500" /> */}
        <Icons type="mask" />
        <h2 className="text-2xl font-semibold">Special Occasions</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <textarea
          className="w-100 py-2 px-3 min-h-[200px] border-none rounded-3 mb-4 resize-none placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200"
          placeholder="Write Here e.g wedding Anniversary "
          style={{ borderColor: assets.colors.exp_card_border }}
          value={formik.values.occasion}
          name="occasion"
          rows={5}
          maxLength={250}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div className="d-flex gap-4">
          {/* <button
            type="button"
            onClick={handleCancel}
            className="flex-1 py-3 px-6 border border-red-500 text-red-500 rounded-lg hover:bg-red-50 transition-colors"
          >
            Cancel
          </button> */}
            <Button
                variant="outlined"
                size="medium"
                onClick={onCancel}
                style={{
                  borderRadius: '12px',
                  color: 'black',
                  textTransform: 'capitalize',
                //   backgroundColor: assets.colors.white_400,
                  color:assets.colors.primary,
                  border: `1px solid ${assets.colors.primary}`,

                }}
                className='m-2 px-5'
            >
                Cancel
            </Button>

            <Button
                variant="contained"
                size="medium"
                onClick={() =>{
                  formik.handleSubmit();
                }}
                style={{
                  borderRadius: '12px',
                  color: 'black',
                  textTransform: 'capitalize',
                  backgroundColor: assets.colors.primary,
                  color:"white",
                  border: `1px solid ${assets.colors.primary}`,

                }}
                className='m-2 px-5'
            >
                Submit
            </Button>
        </div>
      </form>
    </div>
  );
};

export default SpecialOccasions;