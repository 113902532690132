import React from "react";
import { Check } from "lucide-react";
import ScreenTemplate from "../../components/ScreenTemplate";
import MainSideBar from "../../components/MainsideBar";
import MainHeader from "../../components/headers/Mainheader";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import FoodPymPage from "./FoodPymPage";

const FoodFailurePage = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      ContentComponent={<FoodPymPage />}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
    />
  );
};

export default FoodFailurePage;
