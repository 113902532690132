import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ondcRestaurants: [], // Rehydrate state
};

const ondcSlice = createSlice({
  name: 'ondc',
  initialState,
  reducers: {
    saveOndcRestaurants: (state, action) => {
    
        const oldDataIds = state.ondcRestaurants.map((res)=>res?.id);
        const newDataIds = action.payload?.map((res)=>res?.id);

        newDataIds.forEach((newResId, index)=>{
            if(!oldDataIds.includes(newResId)){
                state.ondcRestaurants.push(action.payload[index]);
            }
        })
    },

    clearOndcRestaurants: (state, action) => {
      state.ondcRestaurants = [];
    }

  },
});

export const { saveOndcRestaurants,clearOndcRestaurants } = ondcSlice.actions;
export default ondcSlice.reducer;
