import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainSideBar from "../../components/MainsideBar";
import MainHeader from "../../components/headers/Mainheader";
import ScreenTemplate from "../../components/ScreenTemplate";
import useLocation from "../../common/hooks/useLocation";
import { GOOGLE_API_KEY } from "../../config/keys";
import useWindowWidth from "../../common/hooks/useWindowWidth";
import { Row, Col, Image, Button } from "react-bootstrap";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import { SearchWithFilterAndSort } from "../experience/SearchWithFilterAndSort";

const ExperienceDetailsWeb = () => {
  const { restaurantId, experienceId } = useParams();
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);

  const [search, setSearch] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);

  const navigate = useNavigate();

  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  useEffect(() => {
    if (experienceId) {
      //console.log("Fetching Experience Details API with experienceId:", experienceId);
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, "GET");
    }
  }, [experienceId]);

  //console.log("Experience API Response:", responseData);
  useEffect(() => {
    if (responseData) {
      setExperiences(responseData);
      setFilteredExperiences(responseData);
    }
  }, [responseData]);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    if (searchValue) {
      const filtered = experiences.filter((exp) =>
        exp.name.toLowerCase().includes(searchValue)
      );
      setFilteredExperiences(filtered);
    } else {
      setFilteredExperiences(experiences);
    }
  };
  const [
    restaurantCancel,
    restaurantResponseData,
    restaurantError,
    restaurantLoaded,
    restaurantReset,
    restaurantExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (restaurantId) {
      //console.log("Fetching Restaurant Details API with restaurantId:", restaurantId);
      restaurantExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        "GET"
      );
    }
  }, [restaurantId]);

  //console.log("Restaurant API Response:", restaurantResponseData);
  const [isLiked, setIsLiked] = useState(false); // Track the like state
  const handleLikeClick = () => {
    setIsLiked((prevState) => !prevState); // Toggle the like state
  };

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={
        <>
          <div style={{ padding: "10px 20px" }}>
            <h2
              style={{
                fontSize: "28px",
                color: "#F85032",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              Corporate Experience
            </h2>
            <p style={{ color: "#333", fontSize: "14px", margin: 0 }}>
              Hosting Corporate events are an excellent
            </p>
          </div>
          {/* Search Bar Section */}
          <div style={{ marginBottom: "20px", padding: "10px 20px" }}>
            <SearchWithFilterAndSort
              onChange={handleSearchChange}
              search={search}
              placeHolderText={"Search for experiences"}
              micIcon={true}
            />
          </div>
          {/* Restaurant Header */}
          <div
            style={{ padding: "10px 20px", fontFamily: "Arial, sans-serif" }}
          >
            <div
              style={{ fontSize: "28px", fontWeight: "bold", color: "#000" }}
            >
              {responseData?.restaurantName || "..."}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "12px",
                }}
              >
                <Image
                  className="icon"
                  src={require(`../../assets/images/IconsandImages/${
                    isLiked ? "heartcoloured" : "heart"
                  }.png`)}
                  alt="Like"
                  title="Like"
                  style={{
                    width: "22px",
                    height: "20px",
                    cursor: "pointer",
                    marginTop: "-70px",
                    marginRight: "10px",
                  }}
                  onClick={handleLikeClick} // Add click handler
                />

                <Image
                  className="icon"
                  src={require("../../assets/images/IconsandImages/star 1.png")}
                  alt="Rating"
                  title="Rating"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginTop: "-70px",
                    marginRight: "5px",
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#333",
                    marginTop: "-70px",
                    marginRight: "500px",
                  }}
                >
                  {responseData?.expReviewed?.toFixed(1) || "N/A"}
                </span>
                <Button
                  variant="light"
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                    border: "1px solid #ddd",
                    marginTop: "-70px",
                  }}
                >
                  <Image
                    className="icon"
                    src={require("../../assets/images/IconsandImages/call.png")}
                    alt="Call"
                    title="Call"
                    style={{ width: "18px", height: "18px" }}
                  />
                </Button>
                <Button
                  variant="light"
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                    border: "1px solid #ddd",
                    marginTop: "-70px",
                  }}
                >
                  <Image
                    className="icon"
                    src={require("../../assets/images/ExperiencePageAssets/share.png")}
                    alt="Share"
                    title="Share"
                    style={{ width: "18px", height: "18px" }}
                  />
                </Button>
              </div>
            </div>

            {/* Cuisines  */}
            <div
              style={{
                fontSize: "14px",
                marginTop: "3px",
              }}
            >
              {responseData?.restCuisines &&
              responseData.restCuisines.split(" ").length > 25
                ? responseData.restCuisines.split(" ").slice(0, 25).join(" ") +
                  "..."
                : responseData?.restCuisines}
            </div>

            {/* Address */}
            <div style={{ fontSize: "14px", color: "#666", marginTop: "2px" }}>
              {responseData?.venueAddress}
            </div>

            {/* Open Status & Price */}
            <div
              style={{ fontSize: "14px", color: "#ffa500", marginTop: "5px" }}
            >
              {responseData?.timings?.Monday?.open ? "Open now" : "Closed"} -
              11am-12pm | ₹1500 for two
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "10px",
            }}
          ></div>
          <div>
            <Row>
              {/* Left Side - Large Image */}
              <Col md={6}>
                {responseData?.photos?.length > 0 ? (
                  <Image
                    src={responseData.photos[0]}
                    alt="Event"
                    style={{
                      width: "100%",
                      height: "50%",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </Col>
              <Col
                md={6}
                className="d-flex flex-column justify-content-between"
              >
                {responseData?.photos?.length > 1 ? (
                  <Image
                    src={responseData.photos[1]}
                    alt="Celebration"
                    style={{
                      width: "100%",
                      height: "25%",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {/* Cancel Button */}
            <Button
              variant="outline-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "1px solid red",
                color: "#fc5a47",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <Image
                src={require("../../assets/images/IconsandImages/cancelled.png")}
                alt="Cancel"
                style={{ width: "16px", height: "16px" }}
              />
              Cancel
            </Button>

            {/* Chat Button */}
            <Button
              variant="outline-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "1px solid red",
                color: "#fc5a47",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <Image
                src={require("../../assets/images/IconsandImages/chat.png")}
                alt="Chat"
                style={{ width: "16px", height: "16px" }}
              />
              Chat
            </Button>

            {/* Help Button */}
            <Button
              variant="outline-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "1px solid red",
                color: "#fc5a47",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <Image
                src={require("../../assets/images/IconsandImages/chat.png")}
                alt="Help"
                style={{ width: "16px", height: "16px" }}
              />
              Help
            </Button>

            {/* Add Items Button */}
            <Button
              variant="outline-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "1px solid red",
                color: "#fc5a47",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <Image
                src={require("../../assets/images/IconsandImages/addIcon.png")}
                alt="Add Items"
                style={{ width: "16px", height: "16px" }}
              />
              Add Items
            </Button>

            {/* Share Button */}
            <Button
              variant="outline-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                borderRadius: "20px",
                border: "1px solid red",
                color: "#fc5a47",
                backgroundColor: "white",
                cursor: "pointer",
              }}
            >
              <Image
                src={require("../../assets/images/IconsandImages/share.png")}
                alt="Share"
                style={{ width: "16px", height: "16px" }}
              />
              Share
            </Button>

            {/* Book Now Button */}
            <Button
              variant="danger"
              style={{
                padding: "10px 25px",
                borderRadius: "20px",
                backgroundColor: "#fc5a47",
                border: "none",
                color: "white",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/experience/${restaurantId}/${experienceId}/booking`);
              }}
            >
              Book Now
            </Button>
          </div>
        </>
      }
    />
  );
};

export default ExperienceDetailsWeb;
