import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Dropdown,
  Image,
} from "react-bootstrap";


const OndcSeachBar = ({ onChange, setRestaurants, coordinates }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
        setIsSearchFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="position-relative"
      style={{
        width: "100%",
      }}
    >
      <Form
        onSubmit={(e)=>{
            onChange(searchTerm)
        }}
        className="position-relative"
        style={{ maxWidth: "100%" }}
      >
        <InputGroup
          className="border-2 border-[#EFEFEF] rounded-3xl h-12 bg-[#FBFBFB]"
          style={{
            boxShadow: "none",
            color: "#f8f9fa",
          }}
        >
          <InputGroup.Text
            className="bg-white border-0 ps-3"
            style={{ borderRadius: "50px 0 0 50px" }}
          >
            <Image
              src={require("../../../assets/images/IconsandImages/search.png")}
              alt="Search Icon"
              className="w-5 h-5 object-contain"
            />
          </InputGroup.Text>
          <FormControl
            ref={searchInputRef}
            type="text"
            placeholder="Search for restaurants / cuisines or dishes"
            value={searchTerm}
            onChange={(e) => {
                onChange(e.target.value)
                setSearchTerm(e.target.value)
            }}
            onFocus={() => {
              setIsSearchFocused(true);
            }}
            style={{
              borderRadius: "50px",
              paddingLeft: "10px",
              border: "none",
              boxShadow: "none",
            }}
          />
          <InputGroup.Text
            className="bg-white border-0 pe-3"
            style={{ borderRadius: "0 50px 50px 0" }}
          >
            <Image
              src={require("../../../assets/images/IconsandImages/mic.png")}
              alt="Microphone Icon"
              className="w-5 h-5 object-contain"
            />
          </InputGroup.Text>
        </InputGroup>
        <span
          style={{
            position: "absolute",
            right: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <Image
            src={require("../../../assets/images/IconsandImages/qrcode.png")}
            alt="QR Code Icon"
            className="w-5 h-5 object-contain"
          />
        </span>
      </Form>
      {showDropdown && searchResults.length > 0 && isSearchFocused && (
        <Dropdown.Menu
          show
          ref={dropdownRef}
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            width: "100%",
            position: "absolute",
            top: "100%",
            zIndex: "1",
          }}
        >
          {searchResults.map((result) => (
            <Dropdown.Item
              key={result._id}
              onClick={() => {
                setRestaurants([result]);
                setTimeout(() => setShowDropdown(false), 100);
                //console.log("Selected restaurant:", [result]);
              }}
            >
              <div>{result.restaurant_name}</div>
              <small>{result.restaurant_address}</small>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
};

export default OndcSeachBar;
