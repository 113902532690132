import React from 'react';
import { Typography, Button } from '@mui/material';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const WebViewComponent = ({ accessToken }) => {

  return <div className='d-flex align-items-center justify-content-center flex-column gap-3'>
    <PrimaryButton variant="contained" onClick={()=>{
      window.location.href = `https://amealio.scenes.social/oauth2/callback#access_token=${accessToken}`;
    }}>
      Go To Community
    </PrimaryButton>
    <Typography variant="p" gutterBottom>
      {"Our community is available to this location."}
    </Typography>
  </div>;

};

export default WebViewComponent;
