import { Heart, Phone, Share2, Star } from "lucide-react";
import Icons from "../../../components/Icons";
import { assets } from "../../../assets/assets";
import { useParams } from "react-router-dom";
import useApi from "../../../common/api/hooks/useApi";
import { URL } from "../../../common/api/urls";
import { useState } from "react";


const ExpRestaurantCard = ({data}) => {
  const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));
  const { restaurantId, experienceId } = useParams();
  const { handleRequest, loading } = useApi();
  const [isLoading, setIsLoading] = useState(true);

  const onClickFavorites = async()=>{
    const isFav = userAuth?.User?.experienceFav.includes(experienceId)

    let formData = {
      expId: experienceId,
      restaurantId: restaurantId,
    }

    const favType = isFav ? "remove" : "add";
    
    await handleRequest(
      'post',
      `${URL.FAVORITES}?${favType}=true`,
      formData,
      {
        headers:{Authorization: userAuth.accessToken},
        successMessage: 'Successfully updated favorites',
        errorMessage: 'Favorites update failed',
        onSuccess: async(response) => {
          // fetch all communities from this location  
          let userFavArr = [];
          userFavArr.push(...userAuth?.User?.experienceFav);
          if (!isFav) {
            userFavArr.push(formData?.expId);
          } else {
            if (userFavArr.includes(formData?.expId)) {
              let index = userFavArr.indexOf(formData?.expId);
              if (index > -1) {
                userFavArr.splice(index, 1);
              }
            }
          }
          
          let userData = {...userAuth};
          userData.User.experienceFav = userFavArr;

          sessionStorage.setItem("userInformation", JSON.stringify(userData));
          setIsLoading(false)
        },
        onError: (error) => {
          console.log("🚀 ~ error:", error)
      
          setIsLoading(false)
        },
      }
    )
  }


  return (
    <div className=" w-100" style={{ borderRadius: "12px" }}>
      {/* Header Section */}
      <div className="card-header bg-white border-0 d-flex align-items-center justify-content-between">
        <h5 className="card-title mb-0" style={{ fontWeight: "bold" }}>
          {data?.restaurantName}
        </h5>
        <div className="d-flex align-items-center gap-4">
          
          
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{
              padding:"10px 10px",
              backgroundColor:assets.colors.background,
              borderRadius:"3rem",
              border:`1px solid ${assets.colors.border_clr}`,
              cursor: "pointer",
            }}
            onClick={onClickFavorites}
          >
            <Icons type="heart" color={userAuth?.User?.experienceFav.includes(experienceId) ? "red" :""}/>
          </div>
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{
              padding:"10px 10px",
              backgroundColor:assets.colors.background,
              borderRadius:"3rem",
              border:`1px solid ${assets.colors.border_clr}`,
              cursor: "pointer",
            }}
          >
            <Icons type="star" color={"green"}/>
          </div>
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{
              padding:"10px 10px",
              backgroundColor:assets.colors.background,
              borderRadius:"3rem",
              border:`1px solid ${assets.colors.border_clr}`,
              cursor: "pointer",
              height:"2.5rem",
              width:"2.5rem"

            }}
          >
            <Icons type="phone" />
          </div>
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{
              padding:"10px 10px",
              backgroundColor:assets.colors.background,
              borderRadius:"3rem",
              border:`1px solid ${assets.colors.border_clr}`,
              cursor: "pointer"
            }}
          >
            <Icons type="share" />
          </div>
        </div>
      </div>

      {/* Image Section */}
      <img
        src={data?.photos[0]}
        alt="Restaurant"
        className="card-img-top"
        style={{ objectFit: "cover", height: "300px", borderRadius: "12px" }}
      />
      
    </div>
  );
};
  
  export default ExpRestaurantCard;
  
