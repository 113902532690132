import React from "react";
import propTypes from "prop-types";
import vegIcon from "../../assets/images/IconsandImages/vegindicator.png";
import heart from "../../assets/images/IconsandImages/heart (1).png";
import itemImages from "../../assets/images/Food/Screenshot_1735811956.png";
import rateIcon from "../../assets/images/IconsandImages/warningstar.png";
import timerIcon from "../../assets/images/IconsandImages/timer.png";
import { NOTAVAILABLEIMAGE } from "../../common/enums/GlobalEnums";

const MenuItemsCard = (props) => {
  const { itemData } = props;

  const displayVegNonVegIcon = () => {
    return itemData.veg;
  };

  return (
    <div className="w-full min-h-[143px] border-[0.3px] rounded-xl md:rounded-2xl shadow-md mb-4">
      <div className="w-full h-10 flex items-center justify-start bg-white md:hidden border-b-[1.5px] px-3 rounded-t-3xl">
        <p className="m-0 p-0 font-Mulish text-xs font-bold">
          Premium- Burger{" "}
        </p>
      </div>
      {/*** Image With Text Box ***/}
      <div className="px-3 py-2 bg-[#F5F6FF] md:bg-white rounded-b-xl md:rounded-t-2xl">
        {/*** Veg Favorite ***/}
        <div className="items-center justify-between hidden md:block">
          <div className="flex items-center justify-between">
            <img
              src={require(`../../assets/images/IconsandImages/${
                displayVegNonVegIcon()
                  ? "vegindicator.png"
                  : "nonveg_indicator.png"
              }`)}
              alt="Item Type"
              title={displayVegNonVegIcon() ? "Veg" : "Nonveg"}
              className="w-4 h-4 object-contain"
            />
            <div className="bg-[#F7F7F7] w-5 h-5 rounded-full flex items-center justify-center shadow-sm">
              <img
                src={heart}
                alt="Favorite"
                title="Favorite"
                className="w-4 h-4 object-contain"
              />
            </div>
          </div>
        </div>
        {/*** Item Image Item Name ***/}
        <div className="w-full flex items-start justify-start gap-2 mt-2">
          <div className="w-1/4 rounded-md">
            <img
              src={itemData?.images?.[0] || NOTAVAILABLEIMAGE}
              alt="Item Image"
              title="Item Image"
              className="w-full h-[85px] object-cover rounded-md"
            />
          </div>
          <div className="w-3/4">
            {/*** Item Name ***/}
            <h4 className="text-base font-Mulish font-bold flex items-center">
              <img
                src={require(`../../assets/images/IconsandImages/${
                  displayVegNonVegIcon()
                    ? "vegindicator.png"
                    : "nonveg_indicator.png"
                }`)}
                alt="Item Type"
                title={displayVegNonVegIcon() ? "Veg" : "Nonveg"}
                className="w-4 h-4 mr-2 object-contain block md:hidden"
              />
              {itemData?.name}
            </h4>
            {/*** Rate Image and Name ***/}
            <div className="w-full flex items-center justify-start">
              <ReuseIconWithText
                iconUrl={rateIcon}
                iconText={itemData?.rating || 0.0}
                textStyle={"text-sm "}
              />
              <div className="mx-4"></div>
              <ReuseIconWithText
                iconUrl={timerIcon}
                iconText={`${itemData?.prepTime || 0}mins`}
                textStyle={"text-sm "}
              />
            </div>
            {/*** Rupees and Quantity ***/}
            <div className="flex items-center justify-between">
              <div className="w-3/4 flex items-center">
                <p className="text-base font-Mulish font-bold text-[#212121] m-0">
                  ₹ {itemData?.size?.[0]?.price || 0}
                </p>
                {/* <div className="mx-2"></div>
                <p className="text-base font-Mulish font-bold text-lightTextColor line-through m-0">
                  ₹ 250
                </p> */}
              </div>
              <div className="w-1/4 text-center">
                <div
                  className="w-[71px] h-[30px] flex items-center justify-center rounded-lg border-[1.5px]"
                  style={{
                    borderColor:
                      itemData?.availability === "AVAILABLE"
                        ? "#7CA2E3"
                        : "text-lightTextColor",
                  }}
                >
                  <p
                    className={`text-xs font-Mulish font-semibold m-0 ${
                      itemData?.availability === "AVAILABLE"
                        ? "text-blue-500"
                        : "text-lightTextColor"
                    }`}
                  >
                    {itemData?.availability === "NOTAVAILABLE"
                      ? "Unavailable"
                      : itemData?.availability === "SOLDOUT"
                      ? "Sold out"
                      : itemData?.availability === "AVAILABLE"
                      ? "Available"
                      : ""}
                  </p>
                </div>
                {/* <p className="text-sm font-Mulish font-bold text-[#212121] ml-2 mb-0">
                  Qty
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReuseIconWithText = ({ iconUrl, iconText, textStyle }) => {
  return (
    <div className="flex items-center">
      <img
        src={iconUrl}
        alt="Item Image"
        title="Item Image"
        className={`w-3 h-3 object-contain rounded-md`}
      />
      <p
        className={`font-Mulish font-bold text-lightTextColor ml-1 mb-0 ${textStyle}`}
      >
        {iconText}
      </p>
    </div>
  );
};

ReuseIconWithText.propTypes = {
  iconUrl: propTypes.string,
  iconText: propTypes.string,
  iconW: propTypes.string,
  iconH: propTypes.string,
  textStyle: propTypes.array || propTypes.object || propTypes.string,
  itemData: propTypes.object,
};

MenuItemsCard.propTypes = {
  itemData: propTypes.object,
};

export default MenuItemsCard;
