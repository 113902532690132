import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import TitleSection from '../ReusableComponent/TitleSection';
import TitleSectionNew from '../ReusableComponent/TitleSectionNew';
import MenuCard from '../ReusableComponent/menuCard';
import { Image } from 'react-bootstrap';
import { CircularProgress, Button } from '@mui/material';
import { Link } from 'react-scroll';
// import { MenuFilterSection } from "./MenuFilterSection";
import { SearchWithFilterAndSort } from '../experience/SearchWithFilterAndSort';
import { AnalyticPage, ITEM_TAGS } from '../../common/enums/GlobalEnums';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import { MenuCardLarge } from '../ReusableComponent/menuCard';
import { FullcardView } from '../ReusableComponent/menuCard';
import CloseIcon from '@mui/icons-material/Close';
import DownloadBar from '../ReusableComponent/downloadar';
// import VideoCard from "./VideoCard";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import OrderTypes from "./OrderTypes";
import CartBar from '../ReusableComponent/CartBar';
import Sidebar from '../homePage/HomeSideBar';
import DineInDrawer from '../ordering/modals/DineInDrawer';
// import NowLaterBar from "./NowLaterBar";
import UserRegistration from '../ReusableComponent/UserRegistration';
import PriceAlert from '../ordering/modals/PriceAlert';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import Header from '../homePage/Header';
import ScreenTemplate from '../../components/ScreenTemplate';
import MainHeader from '../../components/headers/Mainheader';
import MainSideBar from '../../components/MainsideBar';
import useLocation from '../../common/hooks/useLocation';
import { GOOGLE_API_KEY } from '../../config/keys';
import OrderingServices from '../../components/food/OrderingServices';
import { useSelector } from 'react-redux';
// import SwitchMenuModal from "./SwitchMenuModal";

const OndcRestaurantMenu = (props) => {
  const { ID } = useParams();
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const navigate = useNavigate();
  const [ShowSearch, setshowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [itemTag, setItemTag] = useState('All');
  const [vegOnlyFilter, setVegOnlyFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [payloadData, setpayloadData] = useState(null);
  const default_order_type = sessionStorage.getItem('defaultOrderType');
  const DineInSkip = sessionStorage.getItem('DineInSkip');
  const CurbSideSkip = sessionStorage.getItem('CurbSideSkip');
  const [defaultorderType, setDefaultordertype] = useState(default_order_type);
  const [dineInskip, setdineInSkip] = useState(false);
  const storedItemsFromSession = sessionStorage.getItem('cartItems');
  const [storedItems, setStoredItems] = useState(
    storedItemsFromSession ? JSON.parse(storedItemsFromSession) : []
  );
  const [PriceAlertModal, setPriceAlertModal] = useState(false);
  const {
    coordinates,
    currentLocation,
    updateLocation,
    setCurrentLocation,
    location,
    setLocationState,
  } = useLocation(GOOGLE_API_KEY);
  /*** SWITCH MENU DATA ***/
  const [switchMenuType, setSwitchMenuType] = useState(0);
  const [selectedMenuId, setSelectedMenuId] = useState(0);
  const [switchMenuModal, setSwitchMenuModal] = useState(false);
  const [switchMenuArr, setSwitchMenuArr] = useState([]);
  const [menuInfoArr, setMenuInfoArr] = useState([]);

  const ondcRestaurants = useSelector((state) => state.ondc.ondcRestaurants);

  const [state, setState] = useState({
    isLoading: true,
    menuData: undefined,
    restData: undefined,
    restId: ID,
    loadingMenu: true,
  });

  const [items, setItems] = useState([]);
  const [restaurantItems, setRestaurantItems] = useState([]);

  useEffect(() => {
    if (ID) {
      const selectedRest = ondcRestaurants.filter((rest) => rest.id == ID)[0];

      if (selectedRest) {
        if (selectedRest.items?.length) {
          const restItems = selectedRest.items?.map((item) => {
            return {
              _id: item?.id,
              name: item?.descriptor?.name,
              description: item?.descriptor?.short_desc,
              images: item?.descriptor?.images,
              pricing: item?.price?.value,
              size: [
                {
                  calories: 120,
                  price: Number(item?.price?.value),
                },
              ],
              prepTime: 30,
              // primary_tag:[{title:""}],
              rating: 2,
              spice_level: {
                value: true,
                level: 4,
              },
              order_count: 4,
              view_count: 3,
              availability: 'AVAILABLE',
              take_away: {
                sur_charges: 0,
                sizes: [
                  {
                    isDefault: true,
                    price: Number(item?.price?.value),
                  },
                ],
              },
            };
          });

          const data = [
            {
              _id: selectedRest?.id,
              name: selectedRest?.descriptor?.name,
              images: selectedRest?.descriptor?.images,
              items: restItems,
            },
          ];
          setItems(data);
          setRestaurantItems(data);
        }
      }
    }
  }, []);

  const { isLoading, restId } = state;
  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  // eslint-disable-next-line
  const [
    cancelMenu,
    responseDataMenu,
    errorMenu,
    loadedMenu,
    resetMenu,
    executeMenuAPI,
  ] = useAxios();

  /**** ON CLICK SWITCH MENU ACTION ****/
  const onClickSwitchMenuAction = (type) => () => {
    setSwitchMenuModal(!switchMenuModal);
  };
  const handleSubmitSwitchMenu = (index, restInfo) => {
    setSelectedMenuId(index);
    setMenuInfoArr(restInfo?.categories);
    setSwitchMenuModal(false);
    setSwitchMenuType(1);
  };

  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        'GET',
        null
      );
    }

    if (!DineInSkip) {
      sessionStorage.setItem('DineInSkip', 'intial');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //api response handler for get rest details
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        //do any preprocessing if required using preprocessor.js functions
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
      //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (storedItems.length)
      sessionStorage.setItem('cartOrderType', storedItems[0].selectedOrder);
  }, [storedItems]);

  useEffect(() => {
    if (state?.restData != null) {
      executeMenuAPI(
        `${URL.RESTAURANT_ITEMS_V2}?vendor_id=${state?.restData?.vendorId}&orderType=${defaultorderType}&restaurant_id=${state?.restData?._id}`,
        'GET',
        null
      );
    }
    if (!defaultorderType && state?.restData) {
      if (state?.restData?.orderSessionSettings?.dine_in_order) {
        sessionStorage.setItem('defaultOrderType', 'dine_in');
        sessionStorage.setItem('mainDefaultOrderType', 'dine_in');
        setDefaultordertype('dine_in');
      } else if (state?.restData?.orderSessionSettings?.curb_side) {
        sessionStorage.setItem('defaultOrderType', 'curb_side');
        sessionStorage.setItem('mainDefaultOrderType', 'curb_side');
        setDefaultordertype('curb_side');
      } else {
        sessionStorage.setItem('defaultOrderType', 'take_away');
        sessionStorage.setItem('mainDefaultOrderType', 'take_away');
        setDefaultordertype('take_away');
      }
    }
  }, [state?.restData, defaultorderType]);

  useEffect(() => {
    if (loadedMenu) {
      if (responseDataMenu != null) {
        setState((prevState) => ({
          ...prevState,
          menuData: responseDataMenu?.[0]?.categories,
          loadingMenu: false,
        }));
        setSwitchMenuArr(responseDataMenu);
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          loadingMenu: false,
        }));
      }
      reset(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMenu, responseDataMenu]);

  useEffect(() => {
    if (menuInfoArr !== undefined && menuInfoArr !== null) {
      setState((prevState) => ({
        ...prevState,
        menuData: menuInfoArr,
        loadingMenu: false,
      }));
    }
  }, [menuInfoArr]);

  // eslint-disable-next-line
  const [
    filterCancel,
    filterResponseData,
    filterError,
    filterLoaded,
    filterReset,
    filterExecuteAPI,
  ] = useAxios();

  const applyFilter = (filterState) => {
    const filteredItems = restaurantItems[0]?.items?.filter((item) => {
      const itemName = item?.name.toLowerCase();
      const text = filterState?.search;

      const regex = new RegExp(text.toLowerCase(), 'i'); // "i" flag makes it case-insensitive
      return itemName && regex.test(itemName);
    });

    // const newData = {...items, items:filteredItems}
    const newData = [
      {
        _id: restaurantItems[0]?._id,
        name: restaurantItems[0]?.name,
        images: restaurantItems[0]?.images,
        items: filteredItems,
      },
    ];

    setItems(newData);
  };
  useEffect(() => {
    if (filterResponseData?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        menuData: filterResponseData,
        loadingMenu: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        menuData: [],
      }));
    }
  }, [filterResponseData]);

  useEffect(() => {
    if (defaultorderType === 'curb_side' && CurbSideSkip === 'intial') {
      navigate(`/restaurant/${restId}/curbside-details`);
    }
  }, [defaultorderType]);

  useEffect(() => {
    setdineInSkip(default_order_type === 'dine_in' && DineInSkip === 'intial');
  }, [default_order_type]);
  const handleClose = () => {
    setOpen(false);
  };

  const openCatModal = () => {
    setOpen(true);
  };
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    applyFilter({ search: e.target.value });
  };

  const SearchFun = () => {
    if (ShowSearch === true) {
      setState((prevState) => ({
        ...prevState,
        // menuData: responseDataMenu,
        menuData: responseDataMenu?.[0]?.categories,
      }));
      // setState(({...state,menuData:responseDataMenu}))
      setSearch('');
    }
    setshowSearch(!ShowSearch);
  };

  let filterdata = items;

  // let filterdata = state.menuData;
  // if (itemTag) {
  //   if (itemTag === "All") {
  //     filterdata = state.menuData;
  //   } else {
  //     filterdata = filterdata.filter((tag) => {
  //       const filteritems = tag.items.filter(
  //         (item) => item.primary_tag[0]?.title === itemTag
  //       );
  //       if (filteritems.length > 0) {
  //         return true;
  //       }
  //       return false;
  //     });
  //   }
  // }

  const filteredDataArray = filterdata?.flatMap((menu) => {
    if (menu.items) {
      // return menu.items.filter(
      //   (item) => item.videos && item.videos.length > 0 && item.videos[0] !== ""
      // );

      return menu.items;
    }
    return [];
  });

  // useEffect(() => {
  //   if (vegOnlyFilter) {
  //     applyFilter({ vegOnly: true });
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       // menuData: responseDataMenu,
  //       menuData: responseDataMenu?.[0]?.categories,
  //     }));
  //   }
  // }, [vegOnlyFilter]);

  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);

  const handleVideoClick = (url) => {
    if (currentVideoUrl === url) {
      // If the clicked video is already playing, pause it
      setCurrentVideoUrl(null);
    } else {
      // Set the new video to play and pause the previously playing video
      setCurrentVideoUrl(url);
    }
  };

  const [viewType, setViewType] = useState(filteredDataArray?.length ? 1 : 1);

  useEffect(() => {
    if (filteredDataArray?.length) {
      setViewType(1);
    } else {
      setViewType(1);
    }
  }, [filteredDataArray?.length]);
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [showDineInModal, setshowDineInModal] = useState(false);
  const openPriceAlert = () => {
    setPriceAlertModal(true);
  };
  const [proceedStatus, setProceedStatus] = useState(false);

  const closePriceAlert = (status) => {
    setProceedStatus(status);
    setPriceAlertModal(false);
  };
  const content = (
    <div>
      <Row>
        <Col>
          {isLoading ? (
            <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
              <CircularProgress sx={{ marginTop: '20%' }} />
            </div>
          ) : (
            <div>
              <div className="floatingBtn">
                <Image
                  src={require('../../assets/images/IconsandImages/categoriesbtn.png')}
                  alt="icon"
                  width="132px"
                  style={{ maxWidth: '100%' }}
                  className="cursor-pointer"
                  onClick={() => setOpen(true)}
                />
              </div>
              {ShowSearch && (
                <div className="p-4">
                  <SearchWithFilterAndSort
                    onChange={onChangeSearch}
                    search={search}
                    placeHolderText={'Search by items'}
                    clearbtn={true}
                    onclicksearch={SearchFun}
                  />
                </div>
              )}

              {showOtpModal ? (
                <UserRegistration
                  open={showOtpModal}
                  setshowOtpModal={(val) => setshowOtpModal(val)}
                  //  setshowDineInModal={(val) => setshowDineInModal(val)}
                />
              ) : null}

              {PriceAlertModal ? (
                <PriceAlert
                  closePriceAlert={closePriceAlert}
                  openModal={PriceAlertModal}
                />
              ) : null}

              {windowWidth > 1024 ? (
                <>
                  <TitleSectionNew
                    bg={'#FFFFFF'}
                    title={restaurantItems[0]?.name}
                    resticon={restaurantItems[0]?.images[0]}
                    icon={require('../../assets/images/IconsandImages/search.png')}
                    onclicksearch={SearchFun}
                    rating={state?.restData?.rating}
                    reviews={state?.restData?.reviews}
                    time={state?.restData?.orderBufferTime}
                    distance={state?.restData?.userDistance || '3-5'}
                  />
                </>
              ) : (
                <></>
              )}

              <div className="f14-500  mt-2">
                <HorizontalScrollMenu
                  data={[ITEM_TAGS[0]]}
                  onClick={(e) => setItemTag(e)}
                  selected={itemTag}
                />
              </div>

              <hr className="greyhr" />
              {/* <div id="filter">
                <MenuFilterSection
                  vegOnly={vegOnlyFilter}
                  vegonlyfun={setVegOnlyFilter}
                  payloadData={payloadData}
                  restData={state?.restData}
                  applyFilter={applyFilter}
                  switchMenuType={switchMenuType}
                  onClickSwitchMenu={onClickSwitchMenuAction(switchMenuType)}
                />
              </div> */}

              {/* <hr className="greyhr" /> */}
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="f18-700">Browse by Category</div>
                </div>
                <div className="d-flex">
                  <div
                    className="mx-2 cursor-pointer"
                    onClick={() => setViewType(1)}
                  >
                    <Image
                      alt="Filter Icon"
                      src={require(`../../assets/images/IconsandImages/${
                        viewType === 1 ? 'listviewon' : 'listviewoff'
                      }.png`)}
                      width={18}
                    />
                  </div>
                  <div
                    className="mx-2 cursor-pointer"
                    onClick={() => setViewType(2)}
                  >
                    <Image
                      alt="Icon"
                      src={require(`../../assets/images/IconsandImages/${
                        viewType === 2 ? 'cardviewon' : 'cardviewoff'
                      }.png`)}
                      width={18}
                    />
                  </div>
                  {filteredDataArray?.length > 0 ? (
                    <div
                      className="mx-2 cursor-pointer"
                      onClick={() => setViewType(3)}
                    >
                      <PlayCircleOutlineIcon
                        style={{
                          width: '24px',
                          color: `${viewType === 3 ? '#ee3a23' : 'grey'}`,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* {filteredDataArray?.length > 0 && viewType === 3 && (
                <div className="video-container my-4" id="video-container">
                  {filteredDataArray?.map((list, i) => (
                    <VideoCard
                      key={i}
                      url={list.videos[0]}
                      imageurl={list.images[0]}
                      item={list}
                      id={i}
                      isPlaying={currentVideoUrl === list.videos[0]}
                      onVideoClick={() => handleVideoClick(list.videos[0])}
                    />
                  ))}
                </div>
              )} */}
              {filterdata?.map((menu) => {
                return (
                  <Accordion
                    className="my-1"
                    key={menu?._id}
                    defaultExpanded={true}
                    id={menu?.name}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="my-1 mx-3 "
                    >
                      <div className="f18-700">
                        {menu?.name}
                        {'   '}{' '}
                        <span className="f12-700">
                          ({menu?.items?.length} items)
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '20px',
                        }}
                      >
                        {menu?.items?.map((items) => {
                          const display = filteredDataArray.some(
                            (item) => item._id === items._id
                          );
                          return (
                            <div
                              style={{
                                flex: '1 1 calc(50% - 20px)',
                                boxSizing: 'border-box',
                              }}
                              key={items?._id}
                            >
                              {viewType === 1 || viewType === 3 ? (
                                <MenuCard
                                  viewType={viewType}
                                  display={display}
                                  item={items}
                                  key={items?._id}
                                  storedItems={storedItems}
                                  setStoredItems={setStoredItems}
                                  orderSelected={defaultorderType}
                                  restData={{
                                    openStatus: { open: true },
                                  }}
                                  setPriceAlertModal={setPriceAlertModal}
                                />
                              ) : (
                                <MenuCardLarge item={items} key={items?._id} />
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {menu?.combos?.length > 0 && (
                        <div className="w-full">
                          {/* border */}
                          {menu?.combos?.map((comboItemsInfo) => {
                            return (
                              <React.Fragment key={comboItemsInfo?._id}>
                                <div className="f18-700 px-3 mb-3">
                                  {menu?.name + ' ' + 'Combos' + ' '}
                                  <span className="f12-700">
                                    ({menu?.combos?.length} items)
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '20px',
                                  }}
                                >
                                  {/* {menu?.comboItems?.map((items) => {
                                    const display = filteredDataArray.some(
                                      (item) => item._id === items._id
                                    );
                                    return ( */}
                                  <div
                                    style={{
                                      flex: '1 1 calc(50% - 20px)',
                                      boxSizing: 'border-box',
                                    }}
                                  >
                                    {viewType === 1 || viewType === 3 ? (
                                      <MenuCard
                                        viewType={viewType}
                                        displayType={'COMBO'}
                                        display={true}
                                        item={comboItemsInfo}
                                        key={comboItemsInfo?._id}
                                        storedItems={storedItems}
                                        setStoredItems={setStoredItems}
                                        orderSelected={defaultorderType}
                                        restData={state?.restData}
                                        setPriceAlertModal={setPriceAlertModal}
                                      />
                                    ) : (
                                      <MenuCardLarge
                                        item={comboItemsInfo}
                                        key={comboItemsInfo?._id}
                                        displayType={'COMBO'}
                                      />
                                    )}
                                  </div>
                                  {/* );
                                  })} */}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              <Dialog open={open} onClose={handleClose} className="scrollable">
                <div className="scrollable">
                  {filterdata?.map((cat) => {
                    return (
                      <div
                        className="m-4 f16-700 cursor-pointer"
                        key={cat?._id}
                      >
                        <Link
                          activeClass="active"
                          to={cat.name || 'filter'}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          onClick={handleClose}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div> {cat.name} </div>
                            <div className="f12-700 mx-1">
                              {' '}
                              ({cat?.items?.length} items)
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                  <div className="flexCenter my-3">
                    {' '}
                    <CloseIcon
                      style={{
                        border: '0.5px solid black',
                        borderRadius: '50%',
                      }}
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </Dialog>
              <DineInDrawer open={dineInskip} setOpen={setdineInSkip} />
              <div className="px-3 mb-4" style={{ paddingBottom: '80px' }}>
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/fssai.png')}
                    height="40px"
                    width="70px"
                  />
                </div>
                <div className="f12-400 greycolor">{`Lic. No.${
                  state?.restData?.fssai_code?.length != 0
                    ? state.restData?.fssai_code
                    : 'No Available'
                } `}</div>
              </div>

              <div
                style={{
                  backgroundColor: 'white',
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                  zIndex: '100',
                }}
              >
                <div className="row w-100 m-0">
                  <div className="col-10  p-0">
                    {/* <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/Union 88.png')}
                    style={{ width: '100%' }}
                  />
                </div> */}
                    <div className="col-12  p-0">
                      {storedItems?.length ? (
                        <CartBar
                          setshowOtpModal={setshowOtpModal}
                          from="ONDC"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {/* {switchMenuModal && (
        <SwitchMenuModal
          isVisible={switchMenuModal}
          selectedId={selectedMenuId}
          items={switchMenuArr}
          handleClose={() => setSwitchMenuModal(false)}
          handleOnSubmit={handleSubmitSwitchMenu}
        />
      )} */}
    </div>
  );

  return (
    <ScreenTemplate
      MainSideBarComponent={MainSideBar}
      HeaderComponent={() => (
        <MainHeader
          showLocation={true}
          disabled={false}
          currentLocation={location?.locationName}
          updateLocation={updateLocation}
          isDesktop={isDesktop}
          sidebarWidth={240}
          setCurrentLocation={setCurrentLocation}
          setLocationState={setLocationState}
        />
      )}
      ContentComponent={content}
    />
  );
};

export default OndcRestaurantMenu;

export const HorizontalScrollMenu = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick('');
    } else {
      if (onClick) onClick(e);
    }
  };
  return (
    <div className="d-flex overflow-scroll hidescrollbar cursor-pointer">
      {data?.map((e) => (
        <div className="p-1" key={e?.title}>
          <div
            onClick={() => onClicked(e?.title)}
            className={`py-2 px-4 f14-400 badge-item text-center py-1 ${
              selected === e?.title ? 'badge-item-selected' : null
            }`}
            style={{
              backgroundColor: e?.code || 'none',
              color: e?.code ? 'black' : 'none',
            }}
          >
            {e?.title || ''}
          </div>
        </div>
      ))}
    </div>
  );
};
