import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomisationPersonalisation from "../ordering/modals/CustomisationPersonalisation";
import { Modal } from "react-bootstrap";
import PriceAlert from "../ordering/modals/PriceAlert";
import { FLAT_CHARGE_KEYS } from "../../common/enums/OrderEnums";
import { isEmpty } from "../../common/utility/helperFunc";

const AddButton = ({
  item,
  storedItems,
  setStoredItems,
  orderSelected,
  restData,
  setPriceAlertModal,
  actionType,
}) => {
  const [cPModal, setCPModal] = useState(false);
  const [repeatCustomization, setRepeatCustomization] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [cartData, setCardData] = useState({});
  const [tempCartData, setTempCartData] = useState({});
  const cartItem =
    storedItems?.filter((e) => e?.itemId == item?._id)?.[0] || {};

  const getActiveService = () => {
    let activeService = "";
    if (!isEmpty(orderSelected)) {
      /* TAKE_AWAY */
      if (orderSelected === FLAT_CHARGE_KEYS[0]) {
        activeService = "takeAway";
      }
      /* CURB_SIDE */
      if (orderSelected === FLAT_CHARGE_KEYS[1]) {
        activeService = "curbSide";
      }
      /* SKIP_LINE */
      if (orderSelected === FLAT_CHARGE_KEYS[2]) {
        activeService = "skipTheLine";
      }
      /* DINE_IN */
      if (orderSelected === FLAT_CHARGE_KEYS[3]) {
        activeService = "dineIn";
      }
      /* DELIVERY */
      if (orderSelected === FLAT_CHARGE_KEYS[7]) {
        activeService = "delivery";
      }
    }
    return activeService;
  };

  useEffect(() => {
    let prepareCartData = {
      order_items: [
        {
          item: {},
          size: {},
          customised_details: {},
          customised: false,
          quantity: 1,
          addOns: [],
          finalPrice: 0,
          allAddonsTotal: 0,
          notes: "",
          incase_of_unavailable: "",
          sur_charges: [],
          charges: 0,
          counter_number: "",
          category: "",
        },
      ],
    };
    if (actionType === "COMBO") {
      const packingCharges = {
        _id: "6788f8d1e0db3dbe891788bc",
        name: "PKCG - Packaging Charges Packaging",
        value: item.pricing[getActiveService()]?.packingCharges,
        flag: "VALUE",
      };
      let comboSize = {
        available: true,
        calories: "0",
        description: item?.comboName,
        isDefault: true,
        pax: null,
        price: item.pricing[getActiveService()]?.comboPrice,
        size: null,
        uom: "",
        _id: item?._id,
      };
      prepareCartData.order_items[0].comboInfo = item;
      prepareCartData.order_items[0].type = actionType;
      prepareCartData.order_items[0].category = {} || "";
      prepareCartData.order_items[0].item.images = item?.images || "";
      prepareCartData.order_items[0].item.prepTime = "";
      prepareCartData.order_items[0].item.counter_number = 0;
      prepareCartData.order_items[0].item.auto_accept = "";
      prepareCartData.order_items[0].item.veg = item?.isVeg || false;
      prepareCartData.order_items[0].item.itemType = "";
      prepareCartData.order_items[0].item.name = item?.comboName || "";
      prepareCartData.order_items[0].item.category = "";
      prepareCartData.order_items[0].item.description = item?.description || "";
      prepareCartData.order_items[0].item.ingredient_description = "";
      prepareCartData.order_items[0].item.keywords = "";
      prepareCartData.order_items[0].item.id = item?._id || "";
      prepareCartData.order_items[0].veg = item?.isVeg || "";
      prepareCartData.order_items[0].sur_charges = [packingCharges] || [];
      prepareCartData.order_items[0].finalPrice =
        item.pricing[getActiveService()]?.comboPrice;
      prepareCartData.order_items[0].size = comboSize;
    } else {
      prepareCartData.order_items[0].category = item?.category || "";
      prepareCartData.order_items[0].item.images = item?.images || "";
      prepareCartData.order_items[0].item.prepTime = item?.prepTime || "";
      prepareCartData.order_items[0].item.counter_number =
        item?.counter_number || 0;
      prepareCartData.order_items[0].item.auto_accept = item?.auto_accept || "";
      prepareCartData.order_items[0].item.veg = item?.veg || false;
      prepareCartData.order_items[0].item.itemType = item?.itemType || "";
      prepareCartData.order_items[0].item.name = item?.name || "";
      prepareCartData.order_items[0].item.category = item?.category || "";
      prepareCartData.order_items[0].item.description = item?.description || "";
      prepareCartData.order_items[0].item.ingredient_description =
        item?.ingredient_description || "";
      prepareCartData.order_items[0].item.keywords = item?.keywords || "";
      prepareCartData.order_items[0].item.id = item?._id || "";
      prepareCartData.order_items[0].veg = item?.veg || "";
      prepareCartData.order_items[0].itemType = item?.itemType || "";
      prepareCartData.order_items[0].sur_charges =
        item?.[orderSelected]?.sur_charges || [];
      if (item?.[orderSelected]?.sizes) {
        for (let i = 0; i < item[orderSelected].sizes.length; i++) {
          if (item[orderSelected].sizes[i].isDefault) {
            prepareCartData.order_items[0].finalPrice =
              item[orderSelected].sizes[i]?.price;
            prepareCartData.order_items[0].size = item[orderSelected].sizes[i];
          }
        }
      }
    }
    setCardData(prepareCartData);
  }, [item]);

  const addItemToCart = (CLEAR_ITEMS) => {

    console.log("88888")
    let actualCartData = JSON.parse(JSON.stringify(cartData));
    if (actionType === "COMBO") {
      const packingCharges = {
        _id: "6788f8d1e0db3dbe891788bc",
        name: "PKCG - Packaging Charges Packaging",
        value: item.pricing[getActiveService()]?.packingCharges,
        flag: "VALUE",
      };
      actualCartData.order_items[0].sur_charges = [packingCharges];
    } else {
      console.log("8888", item, orderSelected)
      actualCartData.order_items[0].sur_charges =
        item[orderSelected]?.sur_charges;
    }
    actualCartData.order_items[0].itemId = item._id;
    actualCartData.order_items[0].customised =
      item.customizable !== undefined && item.customizable !== null
        ? item.customizable
        : false;
    let actualTotal = cartData.order_items[0].finalPrice;
    let charges = 0;
    for (let i = 0; i < cartData.order_items[0].sur_charges?.length; i++) {
      if (actionType === "COMBO") {
        charges =
          charges + parseFloat(cartData.order_items[0].sur_charges[i].value);
      } else {
        if (cartData.order_items[0].sur_charges[i].flag === "VALUE") {
          charges =
            charges + parseFloat(cartData.order_items[0].sur_charges[i].value);
        } else {
          let percent =
            (actualTotal *
              parseFloat(cartData.order_items[0].sur_charges[i].value)) /
            100;
          charges = charges + percent;
        }
      }
    }
    actualCartData.order_items[0].charges = charges;
    actualCartData.order_items[0].selectedOrder = orderSelected;
    const dataToStore = actualCartData.order_items[0];
    let data = [...storedItems, dataToStore];
    if (CLEAR_ITEMS == "CLEAR_ITEMS") {
      data = [dataToStore];
    }

    console.log("988888", data)
    sessionStorage.setItem("cartItems", JSON.stringify(data));
    setStoredItems(JSON.parse(sessionStorage.getItem("cartItems")));
  };

  const checkIfOrderTypeChanged = async () => {
    return storedItems.some((e) => e?.selectedOrder !== orderSelected);
  };

  const handleAdd = async (actualCartData) => {
    // Add the current item to the array
    // setAddedItems([...addedItems, item]);
    const orderTypeVary = await checkIfOrderTypeChanged();

    const itemExists = storedItems.some(
      (e) => e?.itemId === actualCartData?._id
    );
    if (storedItems.length && orderTypeVary) {
      setOpenModal(true);
      setTempCartData(actualCartData);
    } else if (!orderTypeVary || !storedItems?.length) {
      if (itemExists && !(item?.customizable || item?.personalization_status)) {
        // If the item exists, increase the count
        let updatedItems = storedItems;
        for (let index = storedItems.length - 1; index >= 0; index--) {
          const e = storedItems[index];
          if (e?.itemId === item?._id) {
            updatedItems[index]["quantity"] =
              (updatedItems[index].quantity || 1) + 1;
            break;
          }
        }
        // const updatedItems = storedItems.map((e) => {
        //   if (e?.itemId === item?._id) {
        //     // Increase the count of the existing item
        //     return { ...e, quantity: (e.quantity || 1) + 1 };
        //   }
        //   return e;
        // });
        sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      } else {
        // If the item doesn't exist, add it to storedItems
        const updatedItems = [...storedItems, ...actualCartData.order_items];
        sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
      }
      setStoredItems(JSON.parse(sessionStorage.getItem("cartItems")));
    }
  };

  const addItemsAfterPriceAlertAccept = async () => {
    await setStoredItems([]);
    sessionStorage.removeItem("cartItems");
    if (
      item.customizable ||
      item.personalization_status ||
      item?.[orderSelected]?.sizes?.length > 1
    ) {
      setCPModal(true);
    } else {
      addItemToCart("CLEAR_ITEMS");
    }
  };
  const priceAlertHandleAlert = () => {};

  const handleSubtract = () => {
    for (let i = storedItems.length - 1; i >= 0; i--) {
      if (cartItem?.itemId == storedItems[i].itemId) {
        if (storedItems[i].quantity > 1) {
          storedItems[i].quantity -= 1;
        } else {
          storedItems.splice(i, 1);
        }
        break;
      }
    }
    sessionStorage.setItem("cartItems", JSON.stringify(storedItems));
    setStoredItems(JSON.parse(sessionStorage.getItem("cartItems")));
  };

  const onClickContinue = (actualCartData) => {
    handleAdd(actualCartData);
  };

  const handleAdditems = async () => {
    console.log("4444")
    if (actionType === "COMBO") {
      if (!cartItem.quantity) {
        addItemToCart();
      }
    } else {
      if (!cartItem.quantity) {
        if (
          item.customizable ||
          item.personalization_status ||
          item?.[orderSelected]?.sizes?.length > 1
        ) {
          setCPModal(true);
        } else {
          const orderTypeVary = await checkIfOrderTypeChanged();
          if (storedItems.length && orderTypeVary) {
            setOpenModal(true);
          } else addItemToCart();
        }
      }
    }
  };

  const handleCloseRepeatSize = () => {
    setRepeatCustomization(false);
  };

  const handleClickYes = () => {
    // If the item exists, increase the count
    let updatedItems = storedItems;
    for (let index = storedItems.length - 1; index >= 0; index--) {
      const e = storedItems[index];
      if (e?.itemId === item?._id) {
        updatedItems[index]["quantity"] =
          (updatedItems[index].quantity || 1) + 1;
        break;
      }
    }
    // const updatedItems = storedItems.map((e) => {
    //   if (e?.itemId === item?._id) {
    //     // Increase the count of the existing item
    //     return { ...e, quantity: (e.quantity || 1) + 1 };
    //   }
    //   return e;
    // });
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setStoredItems(JSON.parse(sessionStorage.getItem("cartItems")));

    handleCloseRepeatSize();
  };

  const handleClickNo = () => {
    setCPModal(true);
    handleCloseRepeatSize();
  };

  const handleRepeat = async () => {
    const orderTypeVary = await checkIfOrderTypeChanged();
    if (storedItems?.length && orderTypeVary) {
      setOpenModal(true);
    } else setRepeatCustomization(true);
  };

  const renderContent = () => {
    if (!cartItem?.quantity) {
      return (
        <>
          {actionType === "COMBO" ? (
            <div onClick={handleAdditems} className="f14-700">
              {item?.availability ? "Add" : "Unavailable"}
            </div>
          ) : (
            <div onClick={handleAdditems} className="f14-700">
              {item?.availability === "NOTAVAILABLE"
                ? "Unavailable"
                : item?.availability === "SOLDOUT"
                ? "Sold out"
                : item?.availability === "AVAILABLE"
                ? "Add"
                : ""}
              {/* {item?.availability === 'NOTAVAILABLE'
              ? 'Unavailable'
              : item?.availability === 'SOLDOUT'
              ? 'Soldout'
              : ''} */}
            </div>
          )}
        </>
      );
    } else {
      const totalQuantity = storedItems
        ?.filter((e) => e?.itemId === item?._id)
        .reduce(
          (total, currentItem) => total + (currentItem?.quantity || 0),
          0
        );
      return (
        <>
          <IconButton
            onClick={handleSubtract}
            size="small"
            className="min-w-6 h-7"
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" className="min-w-6 h-7">
            {totalQuantity || 0}
          </IconButton>
          <IconButton
            onClick={() => {
              if (actionType === "COMBO") {
                handleAdd(item);
              } else {
                item.size.length > 0 &&
                (item?.customizable ||
                  item?.personalization_status ||
                  item?.[orderSelected]?.sizes?.length > 1)
                  ? handleRepeat()
                  : handleAdd(item);
              }
            }}
            size="small"
            className="min-w-6 h-7"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </>
      );
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        // onClick={handleAdd}
        disabled={
          actionType !== "COMBO"
            ? item?.availability !== "AVAILABLE" || !restData?.openStatus?.open
            : false
        }
        className="w-full"
        sx={{
          borderRadius: "10px",
          textTransform: "none",
          // paddingX: "5px",
          // paddingY: "2px",
        }}
      >
      <>{renderContent()}</>
      </Button>
      <div className="f10-400 text-center opacity-75 h-5">
        {item?.customizable
          ? "Customize"
          : item?.personalization_status
          ? "Personalize"
          : item?.[orderSelected]?.sizes?.length > 1
          ? "Size Option"
          : ""}
        {/* {item?.customizable ||
        item?.[orderSelected]?.sizes?.length > 1 ||
        item?.personalization_status
          ? item?.customizable
            ? 'Customize'
            : item?.personalization_status
            ? 'Personalize'
            : item?.[orderSelected]?.sizes?.length > 1
            ? 'Size Option'
            : ''
          : item?.availability === 'NOTAVAILABLE'
          ? 'Unavailable'
          : item?.availability === 'SOLDOUT'
          ? 'Soldout'
          : ''} */}
      </div>
      <React.Fragment>
        {repeatCustomization && (
          <Modal
            show={repeatCustomization}
            onHide={handleCloseRepeatSize}
            centered
          >
            <Modal.Header className="py-0 pt-2 border-0" closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="f16-500">
              Do you want to repeat with same size ?
            </Modal.Body>
            <Modal.Footer className="justify-content-start border-0">
              <Button
                variant="outline-secondary"
                className="btn-lg mr-4 px-5"
                onClick={() => handleClickNo()}
                //onClick={handleCloseFlag}
              >
                No
              </Button>
              <Button
                variant="danger"
                className="btn-lg px-5"
                onClick={() => handleClickYes()}
                //onClick={() => handleFlagUser(props.user._id, props.user.isFlag)}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </React.Fragment>
      <CustomisationPersonalisation
        item={item}
        cartItem={cartItem}
        open={cPModal}
        setOpen={setCPModal}
        orderSelected={orderSelected}
        onClickContinue={(e) => onClickContinue(e)}
      />
      <PriceAlert
        open={open}
        setOpenModal={setOpenModal}
        addItemsAfterPriceAlertAccept={addItemsAfterPriceAlertAccept}
      />
    </>
  );
};

export default AddButton;

export const CartPageAddButton = ({
  item,
  incrementHander,
  decrementHandler,
}) => {
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ mx: 2, borderRadius: "10px", textTransform: "none" }}
      >
        <IconButton size="small" onClick={decrementHandler}>
          <RemoveIcon fontSize="small" />
        </IconButton>
        {item.quantity}
        <IconButton size="small" onClick={incrementHander}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Button>
    </>
  );
};
