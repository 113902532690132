import React, { useState } from 'react';

const QuantityCounter = ({ 
  initialValue = 1, 
  min = 0, max = 99 ,
  formik={},
  onIncrementHandler=()=>{},
  onDecrementHandler=()=>{},
}) => {
  const [count, setCount] = useState(formik.values.adultCount);

  const handleIncrement = () => {
    if (count < max) {
      setCount(prev => prev + 1);
      onIncrementHandler('Adult', formik.values)
    }
  };

  const handleDecrement = () => {
    if (count > min) {
      setCount(prev => prev - 1);
      onDecrementHandler('Adult', formik.values)
    }
  };

  return (
    <div className="d-inline-flex align-items-center border rounded-2 p-1" style={{ borderColor: '#a78bfa' }}>
      <button
        onClick={handleDecrement}
        className="btn btn-link text-purple p-0 text-decoration-none fw-medium"
        style={{ color: 'black', width: '24px', height: '24px' }}
      >
        -
      </button>
      <span className="mx-2 text-center " style={{color:'#a78bfa'}}>
        {count}
      </span>
      <button
        onClick={handleIncrement}
        className="btn btn-link text-purple p-0 text-decoration-none fw-medium"
        style={{ color: 'black', width: '24px', height: '24px' }}
      >
        +
      </button>
    </div>
  );
};

export default QuantityCounter;