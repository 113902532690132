import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedRestaurant: null, // Stores the selected restaurant details
  cartItems: [], // Tracks items added to the cart
  orderService: {
    serviceType: null, // E.g., 'DINE_IN', 'HOME_DELIVERY', etc.
    formData: {}, // Stores form data like table number, etc.
    isSkipped: false, // Whether the form was skipped
  },
  paymentStatus: null, // Tracks the payment status
  trackingData: {}, // Tracks order progress after payment
};

const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    setSelectedRestaurant(state, action) {
      state.selectedRestaurant = action.payload;
    },
    addToCart(state, action) {
      state.cartItems.push(action.payload);
    },
    removeFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateCartItem(state, action) {
      const { id, quantity } = action.payload;
      const item = state.cartItems.find((item) => item.id === id);
      if (item) {
        item.quantity = quantity;
      }
    },
    clearCart(state) {
      state.cartItems = [];
    },
    setOrderService(state, action) {
      console.log(action.payload, 'paylod');
      state.orderService.serviceType = action.payload.orderType;
      state.orderService.formData = action.payload.formData || {};
      state.orderService.isSkipped = false; // Reset skip status when a service is set
    },
    skipOrderService(state) {
      state.orderService.isSkipped = true;
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload;
    },
    setTrackingData(state, action) {
      state.trackingData = action.payload;
    },
  },
});

export const {
  setSelectedRestaurant,
  addToCart,
  removeFromCart,
  updateCartItem,
  clearCart,
  setOrderService,
  skipOrderService,
  setPaymentStatus,
  setTrackingData,
} = orderingSlice.actions;

export default orderingSlice.reducer;
