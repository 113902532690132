import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ProfilePicture from './ProfilePicture';
import ProfileSetupButtons from './ProfileSetupBttns';
import { screenPadding } from '../../assets/muiClasses';
import { Box, Grid } from '@mui/material';

const FirstImpressions = () => {
  return (
    <Box>
      <Grid container spacing={2} px={4} sx={{ padding: screenPadding }}>
        <Grid
          item
          xs={12}
          md={6}
          py={4}
          px={2}
          sx={{ maxHeight: '100vh', overflowY: 'scroll' }}
        >
          <ProfilePicture />
          <ProfileSetupButtons />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/Frame.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
            alt="Frame"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstImpressions;
