import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ExperienceGridWeb = ({ data }) => {
  console.log("🚀 ~ ExperienceGridWeb ~ data:", data);
  // console.log("ExperienceGrid", data);
  const navigate = useNavigate();
  const truncateText = (text, maxLength) => {
    return text?.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <div className="f20-500 h-100 w-100 mt-4 position-relative">
      <div
        className="experience-card overlay shadow-sm"
        style={{
          borderRadius: "19px",
          background: `url('${
            data?.photos?.length ? data.photos[0] : "default-image-url"
          }')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "300px", // Adjust height as needed
        }}
      ></div>
      <div className="experience-details d-flex justify-content-between align-items-center mt-2 p-2">
        <div>
          <div className="f16-600">{data?.name || "Unknown Restaurant"}</div>
          <div className="f14-400 text-muted">
            {truncateText(data?.restCuisines || "Cuisine Info", 30)}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Image
            src={require("../../assets/images/IconsandImages/heart.png")}
            alt="Heart"
            style={{ width: 16, height: 16, marginRight: "5px" }}
          />
          <Image
            src={require("../../assets/images/ExperiencePageAssets/star.png")}
            alt="Star"
            style={{ width: 16, height: 16, marginRight: "5px" }}
          />
          <span className="f14-400">
            {data?.expReviewed ? data.expReviewed.toFixed(1) : "N/A"}
          </span>
        </div>
        <button
          className="btn btn-danger py-2 text-white f16-500"
          style={{
            borderRadius: "12px",
            backgroundColor: "#F85032",
            padding: "6px 12px",
            fontSize: "14px",
          }}
          onClick={() =>
            navigate(
              `/restaurant/${data?.restaurantId}/experienceweb/${data?._id}`
            )
          }
        >
          Book Now
        </button>
      </div>
    </div>
  );
};
