import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const Counter = ({ count = 0, onIncrement, onDecrement, bgColor = 'red' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor,
        borderRadius: '12px',
        // padding: '8px 16px',
        margin: '4px 2px',
        color: 'white',
      }}
    >
      {/* Decrement Button */}
      <Button
        variant="text"
        sx={{
          color: 'white',
          minWidth: '30px',
        }}
        onClick={onDecrement}
      >
        -
      </Button>

      {/* Count Display */}
      <Typography
        sx={{
          mx: 2,
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        {count}
      </Typography>

      {/* Increment Button */}
      <Button
        variant="text"
        sx={{
          color: 'white',
          minWidth: '30px',
        }}
        onClick={onIncrement}
      >
        +
      </Button>
    </Box>
  );
};

export default Counter;
