import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import personalInfoImage from '../../assets/images/IconsandImages/personalInfoImage.png';
import healthPreferencesImage from '../../assets/images/IconsandImages/healthPreferencesImage.png';
import healthRestrictionsImage from '../../assets/images/IconsandImages/healthRestrictionsImage.png';
import socialPreferencesImage from '../../assets/images/IconsandImages/socialPreferencesImage.png';
import ExperienceNew from '../../assets/images/IconsandImages/ExperienceNew.png';
import image from '../../assets/images/IconsandImages/image.png';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { assets } from '../../assets/assets';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const ProfileSetupMenu = () => {
  const navigate = useNavigate();

  return (
    <div className="profile-setup-container">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <header className="profile-setup-header">
            <Box className="d-flex justify-content-evenly px-4">
              {' '}
              <Typography className="f28-800">
                {' '}
                Would Love to know you{' '}
              </Typography>
              <Typography
                className="f16-500 pt-2"
                color={assets.colors.greySubText2}
                onClick={() => {
                  navigate('/welcome');
                }}
              >
                Skip
              </Typography>
            </Box>
            <Typography className="f18-500">
              The information you are about to fill is for us to provide a
              better experience.
            </Typography>
          </header>

          <div className="profile-setup-sections hidescrollbar">
            <Section
              backgroundImage={personalInfoImage}
              actionText="Add yours"
              buttonPosition={{ top: '70px', right: '350px' }}
              onActionClick={() => navigate('/profile')}
            />
            <Section
              backgroundImage={healthPreferencesImage}
              actionText="Add yours"
              buttonPosition={{ bottom: '80px', right: '70px' }}
              onActionClick={() => navigate('/dietarypreference')}
            />
            <Section
              backgroundImage={healthRestrictionsImage}
              actionText="Add yours"
              buttonPosition={{ bottom: '70px', left: '70px' }}
              onActionClick={() => navigate('/allergies')}
            />
            <Section
              backgroundImage={socialPreferencesImage}
              actionText="Add yours"
              buttonPosition={{ bottom: '80px', right: '70px' }}
            />
            <Section
              backgroundImage={ExperienceNew}
              actionText="Add yours"
              buttonPosition={{ top: '70px', right: '350px' }}
              onActionClick={() => navigate('/expPreference')}
            />
            <Box className="flexCenter">
              <PrimaryButton
                fullWidth
                className="px-2"
                sx={{ maxWidth: '480px' }}
                onClick={() => navigate('/welcome')}
              >
                Do it later
              </PrimaryButton>
            </Box>
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Decorative" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

const Section = ({
  backgroundImage,
  actionText,
  buttonPosition,
  onActionClick,
}) => {
  const buttonStyle = {
    position: 'absolute',
    ...buttonPosition,
  };

  return (
    <div
      className="profile-setup-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <button
        className="section-action"
        style={buttonStyle}
        onClick={onActionClick}
      >
        {actionText}
      </button>
    </div>
  );
};

export default ProfileSetupMenu;
