import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";

const HomePageSearchBar = ({ setRestaurants, coordinates }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!coordinates?.lat || !coordinates?.lng) {
      console.error("Location coordinates not available.");
      return;
    }
    const formData = {
      lat: coordinates.lat,
      long: coordinates.lng,
      subscribedServices: [],
      popularSelect: 0,
      orderType: "takeaway",
      sortBy: 0,
      costForTwo: { minPrice: 0, maxPrice: 100000 },
      foodType: [],
      foodCategory: [],
      restaurantType: [],
      liquorAvailable: [],
      cuisine: [],
      distance: 20,
      vegOnly: true,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const page = 1;
    const limit = 10;
    const restaurant = "restaurant";
    executeAPI(
      `${URL.SEARCH_RESTAURANT_CARD}?search=${searchTerm}&page=${page}&searchType=${restaurant}&limit=${limit}`,
      "POST",
      formData,
      { headers }
    );
    //console.log("API URL:", `${URL.SEARCH_RESTAURANT_CARD}?search=${searchTerm}&page=${page}&searchType=${restaurant}&limit=${limit}`);
  };

  useEffect(() => {
    if (loaded && responseData) {
      setSearchResults(responseData.data);
      setShowDropdown(true);
    }
    if (error) {
      console.error("Search API Error:", error);
    }
  }, [loaded, responseData, error, setRestaurants]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setShowDropdown(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
        setIsSearchFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="position-relative"
      style={{
        width: "100%",
      }}
    >
      <Form
        onSubmit={handleSearch}
        className="position-relative"
        style={{ maxWidth: "100%" }}
      >
        <InputGroup
          className="border-2 border-[#EFEFEF] rounded-3xl h-12 bg-[#FBFBFB]"
          style={{
            boxShadow: "none",
            color: "#f8f9fa",
          }}
        >
          <InputGroup.Text
            className="bg-white border-0 ps-3"
            style={{ borderRadius: "50px 0 0 50px" }}
          >
            <Image
              src={require("../../assets/images/IconsandImages/foodpagesearch.png")}
              alt="Search Icon"
              className="w-5 h-5 object-contain"
            />
          </InputGroup.Text>
          <FormControl
            ref={searchInputRef}
            type="text"
            placeholder="Search for restaurants / cuisines or dishes"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e);
              //console.log('Search term:', e.target.value);
            }}
            onFocus={() => {
              if (searchResults.length > 0) {
                setShowDropdown(true);
              }
              setIsSearchFocused(true);
            }}
            style={{
              borderRadius: "50px",
              paddingLeft: "10px",
              border: "none",
              boxShadow: "none",
            }}
          />
          <InputGroup.Text
            className="bg-white border-0 pe-3"
            style={{ borderRadius: "0 50px 50px 0" }}
          >
            <Image
              src={require("../../assets/images/IconsandImages/foodmic.png")}
              alt="Microphone Icon"
              className="w-5 h-5 object-contain"
            />
          </InputGroup.Text>
        </InputGroup>
        <span
          style={{
            position: "absolute",
            right: "-30px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <Image
            src={require("../../assets/images/IconsandImages/qrcode.png")}
            alt="QR Code Icon"
            className="w-5 h-5 object-contain"
          />
        </span>
      </Form>
      {showDropdown && searchResults.length > 0 && isSearchFocused && (
        <Dropdown.Menu
          show
          ref={dropdownRef}
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            width: "100%",
            position: "absolute",
            top: "100%",
            zIndex: "1",
          }}
        >
          {searchResults.map((result) => (
            <Dropdown.Item
              key={result._id}
              onClick={() => {
                setRestaurants([result]);
                setTimeout(() => setShowDropdown(false), 100);
                //console.log("Selected restaurant:", [result]);
              }}
            >
              <div>{result.restaurant_name}</div>
              <small>{result.restaurant_address}</small>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
};

export default HomePageSearchBar;
