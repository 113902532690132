import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { assets } from '../../assets/assets';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import { useSelector } from 'react-redux';


const FoodOrderCard = (restaurantData) => {
  const windowWidth = useWindowWidth();

  return (
    <Card
      className="rounded-4 p-0"
      style={{
        borderColor: assets.colors.exp_card_border,
        width: windowWidth <= 768 ? '100%' : '40%',
      }}
    >
      <div className="p-0">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Image Section */}
          <div className="container p-2 m-0 d-flex justify-content-start gap-3">
            <div
              className="col-md-12 col-lg-5 rounded-3"
              style={{ height: '4rem', width: '4rem' }}
            >
              <Image
                src={
                  'src={expRequestDetails?.restaurantId?.logo_url}'
                }
                width={'100%'}
                className="object-contains w-100 rounded-4"
              />
            </div>
            <div className="col-md-12 col-lg-7">
              {/* Content Section */}
              <div className="flex-1 space-y-4">
                <div>
                  <p
                    className="p-0 m-0"
                    style={{ fontWeight: 'bold', fontSize: '1.1em' }}
                  >
                    Restaurant Name
                  </p>
                  <p className="p-0 m-0" style={{ fontSize: '0.8em' }}>
                    Food Item Name
                  </p>
                  <p className="p-0 m-0" style={{ fontSize: '0.8em' }}>
                    {`Order ID: #123456789`}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: '0.8em', color: assets.colors.secondary_blue }}
                  >
                    {`Payment Type: Paid`}
                  </p>
                </div>

                <div className="d-flex align-items-center mt-3 gap-1">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: '1.5rem', height: '1.5rem' }}
                  >
                    <Image
                      src={
                        'https://sellwase-production.s3.amazonaws.com/6777a01dd9da3191da17e878.png'
                      }
                      width={'100%'}
                      className="object-contains w-100 rounded-4"
                    />
                  </div>

                  <p className="p-0 m-0" style={{ fontSize: '0.8em' }}>
                    {`Safe & Secure`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FoodOrderCard;
