import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import userReducer from './slices/userSlice';
import experienceReducer from './slices/expSlice';
import orderingReducer from './slices/orderingSlice';
import locationSlice from './slices/locationSlice';
import ondcSliceRedcer from './slices/ondcSlice';
import notificationReducer from './slices/notificationSlice';

// Persist configuration
const persistConfig = {
  key: 'root', // Single key for all slices
  storage,
  whitelist: ['user', 'experience', 'ordering'], // Specify which slices to persist
};

// Root reducer combining all slices
const rootReducer = {
  user: userReducer,
  location: locationSlice,
  experience: experienceReducer,
  ordering: orderingReducer,
  ondc: ondcSliceRedcer,
  notifications: notificationReducer,
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer)
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
