 import React, { useEffect, useState } from "react";
import { Star, ThumbsUp, ThumbsDown, MessageSquare } from "lucide-react";
import { assets } from "../../../assets/assets";
import { useAxios } from "../../../common/api/hooks/useAxios";
import { URL } from "../../../common/api/urls";
import useApi from "../../../common/api/hooks/useApi";

const ExpReviewRating = ({ experienceId, data, vendorId, count }) => {
  const userAuth = JSON.parse(sessionStorage.getItem("userInformation"));

  const rating = 4.2;
  const maxRating = 5.0;
  const [isLoading, setIsLoading] = useState(true);
  const { handleRequest, loading } = useApi();

  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  console.log("🚀 ~ ExpReviewRating ~ responseData:", responseData);

  useEffect(() => {
    let query = ``;
    if (vendorId) {
      query = `?vendor_id=${vendorId}&type=2`;
    } else if (experienceId) {
      query = `?experienceId=${experienceId}&limit=2&page=1&type=6`;
    }

    if (experienceId || vendorId) {
      executeAPI(`${URL.RATINGS_REVIEWS}${query}`, "GET");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId, count]);

  const handleLikeAndDislike = async (type, id) => {
    await handleRequest(
      "patch",
      `${URL.RATINGS_REVIEWS}/${id}?${type}=true`,
      null,
      {
        headers: { Authorization: userAuth.accessToken },
        successMessage: "Successfully updated favorites",
        errorMessage: "Favorites update failed",
        onSuccess: async (response) => {
          setIsLoading(false);
        },
        onError: (error) => {
          console.log("🚀 ~ error:", error);

          setIsLoading(false);
        },
      }
    );
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <Star
          key={index}
          style={{
            color: "green",
            fill: "green",
            height: "1rem",
            width: "1rem",
          }}
          className="mr-1"
        />
      ));
  };

  return (
    <div className="max-w-6xl my-4">
      <div className="d-flex justify-content-between items-center mb-6 ">
        <h5 className="card-title mb-0" style={{ fontWeight: "bold" }}>
          Reviews & Ratings
        </h5>
        <a href="#" className="text-primary hover:underline">
          View More Reviews
        </a>
      </div>

      <div className="bg-white rounded-lg my-3">
        <div className="d-flex flex-col sm:flex-row justify-content-start align-items-start sm:items-center gap-4">
          <div
            className="bg-success rounded-3 d-flex align-items-center justify-content-center"
            style={{ height: "3.5rem", width: "3.5rem" }}
          >
            <Star
              style={{
                color: "white",
                fill: "white",
                height: "2rem",
                width: "2rem",
              }}
            />
          </div>
          <div>
            <span className="text-3xl font-bold">{rating}</span>
            <span className="text-gray-500">/{maxRating}</span>
            <div className="flex">{renderStars(Math.floor(rating))}</div>
          </div>
        </div>
      </div>

      <div className="container p-0">
        {/* <div class="row row-cols-1 row-cols-md-2 row-cols-lg-6 g-3"> */}
        <div class="d-flex row-cols-1 row-cols-lg-6 overflow-auto gap-3 ">
          {responseData?.map((review) => (
            <div
              key={review.id}
              className="p-4 gap-3 col-lg-4 col-md-12 rounded-3"
              style={{ backgroundColor: assets.colors.ratings_card_bg }}
            >
              <div className="d-flex align-items-center justify gap-3 mb-3">
                <div
                  className="bg-gray-200 rounded-circle overflow-hidden"
                  style={{ height: "3.3rem", width: "3.3rem" }}
                >
                  <img
                    src={review?.user_id?.profile_photo?.[0]}
                    alt={review.author}
                    className="object-cover"
                    style={{ height: "4rem", width: "4rem" }}
                  />
                </div>

                <div className="d-flex align-items-start justify-content-center flex-column">
                  {/* <h3 className="font-semibold">{review.author}</h3> */}
                  <p className="text-sm text-gray-600 m-0">
                    {review?.user_id?.first_name +
                      " " +
                      review?.user_id?.last_name}
                  </p>
                  <p className="text-sm text-muted">Top Critic</p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="mb-3">
                  <span
                    className="text-orange-800 px-3 py-1 rounded-5 text-sm"
                    style={{ backgroundColor: assets.colors.ratings_type_bg }}
                  >
                    {/* {review.category} */}
                    General
                  </span>
                </div>

                <div className="flex mb-3">{renderStars(review.rating)}</div>
              </div>

              <p className="text-gray-700 mb-4">{review?.review}</p>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-4">
                  <div
                    className="d-flex align-items-center gap-1 text-gray-600 hover:text-gray-800"
                    onClick={() => {
                      handleLikeAndDislike("like", review._id);
                    }}
                  >
                    <ThumbsUp className="w-4 h-4" />
                    <span>{review?.likes?.length}</span>
                  </div>
                  <div
                    className="d-flex align-items-center gap-1 text-gray-600 hover:text-gray-800"
                    onClick={() => {
                      handleLikeAndDislike("dislike", review._id);
                    }}
                  >
                    <ThumbsDown className="w-4 h-4" />
                    <span>{review?.dislikes?.length}</span>
                  </div>
                </div>

                <div className="flex items-center gap-1 text-gray-600 hover:text-gray-800">
                  <MessageSquare className="w-4 h-4" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpReviewRating;
