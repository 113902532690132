import React from 'react';
// import { Card, CardContent } from '@/components/ui/card';
import { Card ,Image } from 'react-bootstrap';
import { Play, Timer, Utensils, Wifi, MapPin, Star } from 'lucide-react';
import Icons from '../../../components/Icons';
import { assets } from '../../../assets/assets';
import useWindowWidth from '../../../common/hooks/useWindowWidth';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ExpOrderCard = ({
    restaurantData
}) => {
    const windowWidth = useWindowWidth();
    const { state } = useLocation();

    const expRequestDetails = useSelector((state)=>state.experience?.expTrackDetails)
    const paymentDetails = useSelector((state)=>state.experience?.expTransaction)
    
  return (
    <Card className="rounded-4 p-0 " style={{
        borderColor:assets.colors.exp_card_border,
        width:windowWidth <= 768 ? "100%": "40%"
    }}>
      <div className="p-0">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Image Section */}
          <div className="container p-2 m-0 d-flex  justify-content-start gap-3">
            <div className='col-md-12 col-lg-5 rounded-3' style={{height:"4rem", width:"4rem"}}>
                <Image
                    src={expRequestDetails?.restaurantId?.logo_url}
                    // src={"https://sellwase-production.s3.amazonaws.com/6777a01dd9da3191da17e878.png"}
                    width={'100%'}
                    className='object-contains w-100 rounded-4'
                />
            </div>
            <div className='col-md-12 col-lg-7'>
                {/* Content Section */}
                <div className="flex-1 space-y-4">
                    <div>
                        <p className="p-0 m-0" style={{fontWeight:"bold", fontSize:"1.1em"}} >
                            {expRequestDetails?.restaurantId?.restaurant_name}
                        </p>
                        <p className="p-0 m-0" style={{fontSize:"0.8em"}}>
                            {expRequestDetails?.experienceId?.name}
                        </p>
                        <p className="p-0 m-0" style={{fontSize:"0.8em"}}>
                            {`Experience ID : ${expRequestDetails?.experienceId?.expId}`}
                        </p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-1 w-100">                        
                        <p className="p-0 m-0" style={{fontSize:"0.8em", color:assets.colors.secondary_blue}}>
                            {`Type : ${paymentDetails?.transaction_type ? paymentDetails?.transaction_type : "Pay Later"}`}
                        </p>
                    </div>

                    <div className=' d-flex align-items-center mt-3 gap-1'>
                        <div className="rounded-circle  d-flex align-items-center justify-content-center" 
                             style={{ width: '1.5rem', height: '1.5rem' }}>
                            <Image
                                src={"https://sellwase-production.s3.amazonaws.com/6777a01dd9da3191da17e878.png"}
                                width={'100%'}
                                className='object-contains w-100 rounded-4'
                            />
                        </div>

                        <p className="p-0 m-0" style={{fontSize:"0.8em"}}>
                            {`Covid 19`}
                        </p>
                    </div>
                </div>
            </div>
          </div>

          
        </div>
      </div>
    </Card>
  );
};

export default ExpOrderCard;