import React from "react";
import { Image } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

const ImageSlider = ({ images, captionData, shareLike, shapedivider }) => {
  return (
    <Carousel variant="dark" controls={false} indicators={true} slide>
      {images?.map((data, index) => {
        return (
          <Carousel.Item key={index}>
            {shapedivider && (
              <Image
                src={require("../../assets/images/IconsandImages/Group 87358.png")}
                alt="Image"
                width="40%"
                height="20px"
                style={{ position: "absolute", zIndex: "1060" }}
              />
            )}
            <Image
              src={data}
              alt={`Slide ${index + 1}`}
              style={{
                borderRadius: "0 0 22px 22px",
                zIndex: "1050",
                width: "100%",
                height: "auto",
                maxHeight: "300px", // Ensure this value fits your design
                objectFit: "cover"
              }}
            />
            {shareLike && (
              <Carousel.Caption style={{ top: 0, left: 0, right: 0 }}>
                {shareLike}
              </Carousel.Caption>
            )}
            {captionData && (
              <Carousel.Caption style={{ left: 0 }}>
                {captionData}
              </Carousel.Caption>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ImageSlider;
