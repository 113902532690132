import React, { useRef, useState, useEffect } from "react";

const ImageScroller = () => {
  const images = [
    require("../../assets/images/IconsandImages/splash__1.png"),
    require("../../assets/images/IconsandImages/splash__2.png"),
    require("../../assets/images/IconsandImages/splash__3.png"),
    require("../../assets/images/IconsandImages/splash__4.png"),
    require("../../assets/images/IconsandImages/splash__5.png"),
  ];

  const backgroundImage = require("../../assets/images/IconsandImages/Frame2.png");
  const scrollerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleScroll = () => {
    const scrollLeft = scrollerRef.current.scrollLeft;
    const width = scrollerRef.current.offsetWidth;
    const index = Math.round(scrollLeft / width);
    setActiveIndex(index);
  };

  const handleDotClick = (index) => {
    if (index === images.length) {
      // Directly jump to the first image
      scrollerRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
      setActiveIndex(0);
    } else {
      scrollerRef.current.scrollTo({
        left: index * scrollerRef.current.offsetWidth,
        behavior: "smooth",
      });
      setActiveIndex(index);
    }
  };

  // Auto-scroll logic with looping
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % images.length;

        if (nextIndex === 0) {
          // Jump instantly to the first image without animation
          scrollerRef.current.scrollTo({
            left: 0,
            behavior: "auto",
          });
        } else {
          scrollerRef.current.scrollTo({
            left: nextIndex * scrollerRef.current.offsetWidth,
            behavior: "smooth",
          });
        }

        return nextIndex;
      });
    }, 2000); // Auto-scroll every 2 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const scroller = scrollerRef.current;
    scroller.addEventListener("scroll", handleScroll);

    return () => {
      scroller.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "650px",
        marginTop: "10px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Background Image Container */}
      <div
        style={{
          position: "relative",
          width: "80%", // Constrain the width
          height: "95%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          overflow: "hidden", // Ensure scroller stays inside
        }}
      >
        {/* Image Scroller */}
        <div
          ref={scrollerRef}
          style={{
            display: "flex",
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            scrollbarWidth: "none",
            WebkitOverflowScrolling: "touch",
            width: "100%",
            height: "100%",
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                minWidth: "100%",
                scrollSnapAlign: "start",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={image}
                alt={`Splash ${index + 1}`}
                style={{ width: "400px", height: "400px" }}
              />
            </div>
          ))}
        </div>

        {/* Pagination Dots */}
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {images.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: activeIndex === index ? "red" : "lightgray",
                margin: "0 5px",
                cursor: "pointer",
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageScroller;
