import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const locationState = useLocation();
  const [location, setLocation] = useState('Fetching location...');
  useEffect(() => {
    if (locationState.state?.selectedLocation) {
      //console.log('Selected location from search page:', locationState.state.selectedLocation);
      setLocation(locationState.state.selectedLocation);
    } else {
      // Use Geolocation API to get the current location coordinates
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            //console.log('Geolocation coordinates:', { latitude, longitude });
            fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDdFnMqK0NlhvCJ2ejR_MlTOPNGbu8BhYQ`
            )
              .then((response) => response.json())
              .then((data) => {
                //console.log('Reverse geocoding response:', data);
                if (data.status === 'OK') {
                  // Use the formatted address from the response
                  setLocation(data.results[0].formatted_address);
                } else {
                  setLocation('Location not available');
                }
              })
              .catch(() => setLocation('Error fetching location'));
          },
          () => setLocation('Location permission denied')
        );
      } else {
        //console.error('Geolocation error:');
        setLocation('Geolocation not supported');
      }
    }
  }, [locationState]);

  const handleSearchClick = () => {
    navigate('/search');
  };
  return (
    <Container fluid> {/* Fluid container for full width */}
      <Navbar expand="lg" className="bg-white navbar-light" >
        <Container className="d-flex justify-content-between align-items-center">

          {/* Left side (Location) */}
          <div
            className="d-flex align-items-center text-white"
            style={{ flexBasis: '70%', cursor: 'pointer' }}
            onClick={handleSearchClick} // Redirects to the search page when clicked
          >
            <img
              src={require('../../assets/images/IconsandImages/Group 47762.png')}
              alt="Location"
              width="20"
              height="25"
              className="mr-2"
            />
            <div>
              <span className="font-weight-bold" style={{ fontSize: '0.9rem', color: 'black' }}>{location}</span>
              <p className="mb-0 text-secondary" style={{ fontSize: '0.700rem' }}>Set Or change your current location</p>
            </div>
          </div>

          {/* Right side (User and Notification Icons) */}
          <div className="d-flex align-items-center" style={{ flexBasis: '30%', justifyContent: 'flex-end' }}> {/* Set flex-basis to 30% */}
            <img
              src={require('../../assets/images/IconsandImages/Group 87684.png')} // User Icon
              alt="User"
              width="22"
              height="22"
              className="mx-2"
            />
            <img
              src={require('../../assets/images/IconsandImages/Path 27057.png')} // Notification Icon
              alt="Notifications"
              width="21"
              height="21"
              className="mx-2"
            />
          </div>

        </Container>
      </Navbar>
    </Container>
  );
}

export default Header;
