import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from './SaveButton';
import image from '../../assets/images/IconsandImages/image.png';
import search from '../../assets/images/IconsandImages/search.png';

const FavoriteCuisines = () => {
  const cuisines = [
    'Afghani',
    'African',
    'American',
    'Andhra',
    'Arabian',
    'Asian',
    'Awadhi',
    'Bakery',
    'Barbecue',
    'Bengali',
    'Biryani',
    'British',
    'Burger',
    'Chat',
    'Chettinad',
    'Chinese',
    'Coastal',
    'Coffee',
    'Confectionary',
    'Continental',
    'Desserts',
    'Dosa',
    'Doughnuts',
    'Drinks',
    'European',
    'Fast Food',
    'Finger Food',
    'French',
    'Fusion',
    'Goan',
    'Gujarati',
    'Haleem',
  ];

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Initialize navigate hook
  const navigate = useNavigate();

  const handleCuisineClick = (cuisine) => {
    setSelectedCuisines((prevCuisines) =>
      prevCuisines.includes(cuisine)
        ? prevCuisines.filter((item) => item !== cuisine)
        : prevCuisines.length < 10
        ? [...prevCuisines, cuisine]
        : prevCuisines
    );
  };

  const filteredCuisines = cuisines.filter((cuisine) =>
    cuisine.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="favorite-cuisines-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Your Favorite Cuisines</h1>
            <p>Select maximum 10 (Mandatory)</p>

            {/* Search Icon */}
            <div className="search-container">
              <img src={search} alt="Search" className="search-icon" />
              <input
                type="text"
                placeholder="Search for cuisines"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>

            <div className="options-container">
              {filteredCuisines.map((cuisine) => (
                <div
                  key={cuisine}
                  className={`option ${
                    selectedCuisines.includes(cuisine) ? 'selected' : ''
                  }`}
                  onClick={() => handleCuisineClick(cuisine)}
                >
                  {cuisine} <span className="plus">+</span>
                </div>
              ))}
            </div>

            <SaveButton
              onClick={() => navigate('/Places')} // Navigate to profile setup menu
              active={selectedCuisines.length === 1}
            />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Favorite Cuisines" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default FavoriteCuisines;
