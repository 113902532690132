// src/redux/slices/notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  unreadCount: 0, // Tracks the number of unread notifications
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Set notifications and calculate unread count
    setNotifications(state, action) {
      state.notifications = action.payload;
      state.unreadCount = action.payload.filter(
        (notification) => !notification.read
      ).length;
      state.error = null; // Reset error on successful fetch
    },

    // Mark specific notifications as read and update unread count
    markSpecificAsRead(state, action) {
      const idsToMark = action.payload;
      state.notifications = state.notifications.map((notification) =>
        idsToMark.includes(notification._id)
          ? { ...notification, read: true }
          : notification
      );
      state.unreadCount = state.notifications.filter(
        (notification) => !notification.read
      ).length;
    },

    // Mark all notifications as read and reset unread count
    markAllAsRead(state) {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: true,
      }));
      state.unreadCount = 0;
    },

    // Remove a specific notification and recalculate unread count
    removeNotification(state, action) {
      state.notifications = state.notifications.filter(
        (notification) => notification._id !== action.payload
      );
      state.unreadCount = state.notifications.filter(
        (notification) => !notification.read
      ).length;
    },

    // Clear all notifications and reset unread count
    clearNotifications(state) {
      state.notifications = [];
      state.unreadCount = 0;
    },

    // Set loading state
    setLoading(state, action) {
      state.loading = action.payload;
    },

    // Set error state
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

// Export actions
export const {
  setNotifications,
  markSpecificAsRead,
  markAllAsRead,
  removeNotification,
  clearNotifications,
  setLoading,
  setError,
} = notificationSlice.actions;

// Selector to retrieve notifications
export const selectNotifications = (state) => state.notifications.notifications;

// Selector to retrieve unread count
export const selectUnreadCount = (state) => state.notifications.unreadCount;

// Selector to retrieve loading state
export const selectLoading = (state) => state.notifications.loading;

// Selector to retrieve error state
export const selectError = (state) => state.notifications.error;

// Export reducer
export default notificationSlice.reducer;
